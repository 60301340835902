import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import * as THREE from 'three';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import {
  Container, Paper, Button, Group, Text, Loader, Center, Title,
  Stack, Card, Select
} from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { IconShoppingCart } from '@tabler/icons-react';
import studioHDRI from '../assets/hdri/studio_small_03_1k.hdr';
import smallEarringImg from '../assets/img/Smal.png';
import regularEarringImg from '../assets/img/ModelRegular.png';

// Fix API URL construction
const API_URL = process.env.NODE_ENV === 'production'
  ? 'https://f-oracle-backend.onrender.com/api'  // Production URL with /api
  : 'http://localhost:10000/api';  // Development URL with /api

// Add base API path without double /api
const getApiPath = (endpoint) => {
  return `${API_URL}${endpoint}`;  // API_URL already includes /api
};

const ShopM = () => {
  const [modelPrice, setModelPrice] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isMaterialsLoading, setIsMaterialsLoading] = useState(true);
  const [isModelLoading, setIsModelLoading] = useState(false);
  const [error, setError] = useState(null);
  const [objString, setObjString] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [modelId, setModelId] = useState(null);
  const [modelDimensions, setModelDimensions] = useState(null);
  const [modelVolume, setModelVolume] = useState(null);
  const [modelSurface, setModelSurface] = useState(null);
  const [printLabLink, setPrintLabLink] = useState(null);
  const [materials, setMaterials] = useState([]);
  const [selectedMaterial, setSelectedMaterial] = useState(null);
  const [selectedFinish, setSelectedFinish] = useState(null);
  const [isModelUploaded, setIsModelUploaded] = useState(false);
  const [selectedSize, setSelectedSize] = useState('regular');
  const [hasChangedMaterialOrFinish, setHasChangedMaterialOrFinish] = useState(false);
  const [showImage, setShowImage] = useState(true);  // Controls whether to show image or 3D model
  const [dateChanged, setDateChanged] = useState(false);

  const mountRef = useRef(null);
  const sceneRef = useRef(new THREE.Scene());
  const cameraRef = useRef(null);
  const rendererRef = useRef(null);
  const controlsRef = useRef(null);

  // Helper function to convert frontend size to backend size
  const getBackendSize = (frontendSize) => {
    // Map frontend sizes to backend sizes
    const sizeMap = {
      'regular': 'small',    // Frontend "Regular (35mm)" maps to backend "small"
      'small': 'micro'       // Frontend "Small (25mm)" maps to backend "micro"
    };
    return sizeMap[frontendSize] || frontendSize;
  };

  const initScene = () => {
    if (!mountRef.current) return;

    const width = mountRef.current.clientWidth;
    const height = mountRef.current.clientHeight;

    // Create scene
    sceneRef.current = new THREE.Scene();
    sceneRef.current.background = new THREE.Color(0xffffff);

    // Create camera
    const camera = new THREE.PerspectiveCamera(45, width / height, 0.1, 1000);
    camera.position.set(0, 0, 10);
    camera.lookAt(0, 0, 0);
    cameraRef.current = camera;

    // Create renderer
    const renderer = new THREE.WebGLRenderer({ 
      antialias: true,
      alpha: true,
      logarithmicDepthBuffer: true
    });
    renderer.setSize(width, height);
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    mountRef.current.appendChild(renderer.domElement);
    rendererRef.current = renderer;

    // Create controls
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true;
    controls.dampingFactor = 0.05;
    controls.screenSpacePanning = false;
    controls.minDistance = 5;
    controls.maxDistance = 30;
    controls.enablePan = false;
    controls.enableZoom = true;
    controls.rotateSpeed = 0.5;
    controlsRef.current = controls;

    // Add initial lighting
    adjustLighting();

    return true;
  };

  const generateEarring = async () => {
    setIsLoading(true);
    setError(null);
    // Reset all model information
    setIsModelUploaded(false);
    setModelId(null);
    setModelDimensions(null);
    setModelVolume(null);
    setModelSurface(null);
    setModelPrice(null);
    
    try {
      clearScene();

      const response = await axios.post(getApiPath('/generate-earring'), {
        date: selectedDate.toISOString(),
        withMirror: true,
        size: getBackendSize(selectedSize)  // Convert size for backend
      });

      if (response.data.success) {
        setObjString(response.data.objString);
        console.log('3D model size:', new Blob([response.data.objString]).size / 1024, 'KB');
        setIsLoading(false);
        setIsModelLoading(true);
        
        // Get current material and finish for initial load
        const currentMaterial = materials.find(m => m.value === selectedMaterial);
        const currentFinish = currentMaterial?.finishes.find(f => f.value === selectedFinish);
        
        console.log('Initial material and finish:', {
          material: currentMaterial?.label,
          finish: currentFinish?.label
        });
        
        // Pass current material and finish to loadModel
        loadModel(response.data.objString, currentMaterial, currentFinish);
      } else {
        setError('Failed to generate earring model: ' + (response.data.error || 'Unknown error'));
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error generating earring:', error);
      setError('Failed to generate earring: ' + error.message);
      setShowImage(true);
    } finally {
      setIsLoading(false);
    }
  };

  const clearScene = () => {
    if (sceneRef.current) {
      // Remove all objects from the scene
      while(sceneRef.current.children.length > 0) { 
        const object = sceneRef.current.children[0];
        sceneRef.current.remove(object);
      }
      // Re-add lighting after clearing
      adjustLighting();
    }
  };

  const loadModel = (objString, currentMaterial, currentFinish) => {
    // Clear the scene first
    clearScene();
    
    const loader = new OBJLoader();
    const object = loader.parse(objString);

    // Load HDRI environment map using imported file
    new RGBELoader()
      .load(studioHDRI, function(texture) {
        texture.mapping = THREE.EquirectangularReflectionMapping;
        sceneRef.current.environment = texture;
        sceneRef.current.background = new THREE.Color(0xffffff);
    });

    // Create material based on current material and finish
    const getMaterial = () => {
      // If no material/finish provided, default to gold appearance
      if (!currentMaterial || !currentFinish) {
        return new THREE.MeshStandardMaterial({
          color: 0xffd400,  // Gold color
          metalness: 1.0,
          roughness: 0.15,
          envMapIntensity: 1.5,
        });
      }

      if (currentMaterial.label.toLowerCase().includes('gold')) {
        return new THREE.MeshStandardMaterial({
          color: 0xffd400,  // Gold color
          metalness: 1.0,
          roughness: 0.15,
          envMapIntensity: 1.5,
        });
      }
      // If it's silver material, always show silver
      if (currentMaterial.label.toLowerCase().includes('silver')) {
        return new THREE.MeshStandardMaterial({
          color: 0xC0C0C0,  // Silver color
          metalness: 0.9,
          roughness: 0.3,
          envMapIntensity: 0.8,
        });
      }
      
      // If it's brass material
      if (currentMaterial.label.toLowerCase().includes('brass')) {
        // Show silver for rhodium or chrome finishes
        if (currentFinish.label.toLowerCase().includes('rhodium') || 
            currentFinish.label.toLowerCase().includes('chrome')) {
          return new THREE.MeshStandardMaterial({
            color: 0xC0C0C0,  // Silver color
            metalness: 0.9,
            roughness: 0.3,
            envMapIntensity: 0.8,
          });
        }
        // Show gold for all other brass finishes
        return new THREE.MeshStandardMaterial({
          color: 0xffd400,  // Gold color
          metalness: 1.0,
          roughness: 0.15,
          envMapIntensity: 1.5,
        });
      }
      
      // Default gold material
      return new THREE.MeshStandardMaterial({
        color: 0xffd400,  // Gold color
        metalness: 1.0,
        roughness: 0.15,
        envMapIntensity: 1.5,
      });
    };

    const material = getMaterial();
    object.traverse((child) => {
      if (child instanceof THREE.Mesh) {
        child.material = material;
      }
    });

    sceneRef.current.add(object);

    // Calculate bounding box
    const box = new THREE.Box3().setFromObject(object);
    const center = box.getCenter(new THREE.Vector3());
    const size = box.getSize(new THREE.Vector3());

    // Center the model at origin
    object.position.sub(center);

    // Calculate optimal scale based on bounding box
    const maxDim = Math.max(size.x, size.y, size.z);
    const scale = 8.4 / maxDim;
    object.scale.multiplyScalar(scale);

    // Set initial rotation to 240 degrees around Y axis
    object.rotation.set(0, (240 * Math.PI) / 180, 0);

    // Add rotation animation
    let startRotation = object.rotation.y;
    const targetRotation = startRotation + (20 * Math.PI) / 180; // Add 20 degrees
    const animationDuration = 2000; // 2 seconds
    const startTime = Date.now();

    // Create animation function
    const animateRotation = () => {
      const currentTime = Date.now();
      const elapsed = currentTime - startTime;
      const progress = Math.min(elapsed / animationDuration, 1);

      // Use easeOutCubic for smooth deceleration
      const easeOut = 1 - Math.pow(1 - progress, 3);
      object.rotation.y = startRotation + (targetRotation - startRotation) * easeOut;

      if (progress < 1) {
        requestAnimationFrame(animateRotation);
      }
    };

    // Start the animation
    animateRotation();

    // Recalculate bounding box after scaling
    const scaledBox = new THREE.Box3().setFromObject(object);
    const scaledSize = scaledBox.getSize(new THREE.Vector3());
    const scaledCenter = scaledBox.getCenter(new THREE.Vector3());
    
    // Calculate optimal camera position
    const fov = 35;
    const fovRad = fov * (Math.PI / 180);
    const distance = Math.abs(scaledSize.y / Math.tan(fovRad / 2)) * 1.2;

    // Position camera for back view
    cameraRef.current.position.set(0, 0, distance);
    cameraRef.current.lookAt(scaledCenter);

    // Update camera settings
    cameraRef.current.fov = fov;
    cameraRef.current.near = distance / 200;
    cameraRef.current.far = distance * 5;
    cameraRef.current.updateProjectionMatrix();

    // Update controls
    if (controlsRef.current) {
      controlsRef.current.reset();
      controlsRef.current.target.copy(scaledCenter);
      controlsRef.current.minDistance = distance / 4;
      controlsRef.current.maxDistance = distance * 3;
      // Allow full vertical rotation
      controlsRef.current.minPolarAngle = 0;  // Allow rotation to top
      controlsRef.current.maxPolarAngle = Math.PI;  // Allow rotation to bottom
      controlsRef.current.enablePan = false;
      controlsRef.current.zoomSpeed = 1.2;
      // Temporarily disable controls during animation
      controlsRef.current.enabled = false;
      setTimeout(() => {
        controlsRef.current.enabled = true;
      }, animationDuration);
      controlsRef.current.update();
    }

    adjustLighting();
    setIsModelLoading(false);
  };

  const adjustLighting = () => {
    // Remove existing lights
    sceneRef.current.children = sceneRef.current.children.filter(child => !(child instanceof THREE.Light));

    // Add ambient light (increased for better silver visibility)
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
    sceneRef.current.add(ambientLight);

    // Add key light
    const keyLight = new THREE.DirectionalLight(0xffffff, 0.8);
    keyLight.position.set(5, 5, 5);
    sceneRef.current.add(keyLight);

    // Add fill light
    const fillLight = new THREE.DirectionalLight(0xffffff, 0.4);
    fillLight.position.set(-5, -2, -5);
    sceneRef.current.add(fillLight);

    // Add rim light for silver edge highlights
    const rimLight = new THREE.DirectionalLight(0xffffff, 0.5);
    rimLight.position.set(0, 0, -5);
    sceneRef.current.add(rimLight);

    console.log('Adjusted lighting for silver material');
  };

  const uploadModel = async () => {
    if (!objString) {
      setError('No model uploaded yet.');
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const formData = new FormData();
      const blob = new Blob([objString], { type: 'text/plain' });
      formData.append('file', blob, 'spiralEarring.obj');

      console.log('Upload attempt details:', {
        url: getApiPath('/imaterialise/upload'),
        formDataEntries: Array.from(formData.entries()).map(([key, value]) => ({
          key,
          type: value instanceof Blob ? 'Blob' : typeof value,
          size: value instanceof Blob ? value.size : null
        })),
        blobSize: blob.size,
        blobType: blob.type
      });

      const response = await axios.post(getApiPath('/imaterialise/upload'), formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        validateStatus: function (status) {
          return status < 500;
        }
      });

      console.log('Upload response details:', {
        status: response.status,
        statusText: response.statusText,
        headers: response.headers,
        data: response.data
      });

      if (response.data.success) {
        setIsModelUploaded(true);
        const newModelId = response.data.modelId;
        setModelId(newModelId);
        setModelDimensions({
          x: response.data.dimensions.x,
          y: response.data.dimensions.y,
          z: response.data.dimensions.z
        });
        setModelVolume(response.data.volume);
        setModelSurface(response.data.surface);
        
        // If material and finish are already selected, get the initial price
        if (selectedMaterial && selectedFinish) {
          try {
            const priceRequestBody = {
              models: [{
                modelID: newModelId,
                materialID: selectedMaterial,
                finishID: selectedFinish,
                quantity: "1",
                scale: "1.0"
              }],
              currency: "EUR"
            };

            const priceResponse = await axios.post(getApiPath('/imaterialise/get-price'), priceRequestBody);
            if (priceResponse.data.models?.[0]?.totalPrice) {
              setModelPrice(`${priceResponse.data.models[0].totalPrice} ${priceResponse.data.currency}`);
            }
          } catch (priceError) {
            console.error('Initial price fetch failed:', priceError);
            // Don't fail the upload if price fetch fails
            setModelPrice(null);
          }
        }
      } else {
        throw new Error(response.data.error || 'Upload failed');
      }
    } catch (error) {
      console.error('Upload error details:', {
        message: error.message,
        response: {
          status: error.response?.status,
          statusText: error.response?.statusText,
          data: error.response?.data,
          headers: error.response?.headers
        },
        request: {
          url: error.config?.url,
          method: error.config?.method,
          headers: error.config?.headers
        }
      });
      setError('Failed to upload model: ' + (error.response?.data?.error || error.message));
      setIsModelUploaded(false);
      setModelId(null);
    } finally {
      setIsLoading(false);
    }
  };

  const getPrice = async () => {
    if (!modelId || !selectedMaterial || !selectedFinish) {
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const requestBody = {
        models: [{
          modelID: modelId,
          materialID: selectedMaterial,
          finishID: selectedFinish,
          quantity: "1",
          scale: "1.0"
        }],
        currency: "EUR"
      };

      console.log('Getting price with:', {
        modelId,
        selectedMaterial,
        selectedFinish,
        material: materials.find(m => m.value === selectedMaterial)?.label,
        finish: materials.find(m => m.value === selectedMaterial)?.finishes?.find(f => f.value === selectedFinish)?.label
      });

      const response = await axios.post(getApiPath('/imaterialise/get-price'), requestBody);
      console.log('Price response:', response.data);

      if (response.data.models?.[0]?.totalPrice) {
        const price = `${response.data.models[0].totalPrice} ${response.data.currency}`;
        console.log('Setting price to:', price);
        setModelPrice(price);
      } else if (response.data.models?.[0]?.pricingError) {
        throw new Error(`Pricing error: ${response.data.models[0].pricingError.message}`);
      } else if (response.data.shipmentCost?.shipmentError) {
        throw new Error(`Shipment error: ${response.data.shipmentCost.shipmentError}`);
      } else {
        console.error('Unexpected response format:', response.data);
        throw new Error('Failed to get price information');
      }
    } catch (error) {
      console.error('Error getting price:', error);
      const errorMessage = error.response?.data?.error || 
                          error.response?.data?.models?.[0]?.pricingError?.message ||
                          error.response?.data?.shipmentCost?.shipmentError ||
                          error.message;
      setError('Failed to get price: ' + errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const createCart = async () => {
    if (!modelId || !modelPrice || !selectedMaterial || !selectedFinish) {
      setError('Please select material and finish first.');
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      // Step 1: Create cart item with the exact format needed
      const formData = new FormData();
      const cartItemData = {
        cartItems: [{
          toolID: "e6e28a66-abf7-4385-a2e1-4211edc916df",
          MyCartItemReference: "spiralEarrings",
          modelID: modelId,
          modelFileName: "",
          fileUnits: "mm",
          fileScaleFactor: "1",
          materialID: selectedMaterial,
          finishID: selectedFinish,
          quantity: "1",
          xDimMm: modelDimensions.x.toFixed(4),
          yDimMm: modelDimensions.y.toFixed(4),
          zDimMm: modelDimensions.z.toFixed(4),
          volumeCm3: modelVolume.toFixed(6),
          surfaceCm2: modelSurface.toFixed(4),
          iMatAPIPrice: parseFloat(modelPrice.split(' ')[0]).toFixed(2),
          mySalesPrice: (parseFloat(modelPrice.split(' ')[0]) * 2).toFixed(2)
        }],
        currency: "EUR"
      };

      formData.append('data', JSON.stringify(cartItemData) + ';type=application/json');

      const cartItemResponse = await axios.post(getApiPath('/imaterialise/cart/item'), formData, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data'
        }
      });

      console.log('Cart item creation response:', cartItemResponse.data);

      if (cartItemResponse.data.success && cartItemResponse.data.cartItemId) {
        // Step 2: Create cart with the item
        const cartResponse = await axios.post(getApiPath('/imaterialise/cart'), {
          cartItems: [{
            cartItemID: cartItemResponse.data.cartItemId
          }]
        });

        console.log('Cart creation response:', cartResponse.data);

        if (cartResponse.data.success && cartResponse.data.iMatCartPageUrl) {
          setPrintLabLink(cartResponse.data.iMatCartPageUrl);
          window.open(cartResponse.data.iMatCartPageUrl, '_blank');
        } else {
          throw new Error('Failed to get cart URL from response');
        }
      } else {
        throw new Error('Failed to get cart item ID from response');
      }

      setIsLoading(false);
    } catch (error) {
      console.error('Error creating cart:', error);
      setError('Failed to create cart: ' + (error.response?.data?.details || error.response?.data?.error?.message || error.message));
      setIsLoading(false);
    }
  };

  const checkBackendConnectivity = async () => {
    try {
      const response = await axios.get(getApiPath('/imaterialise/check'));
      console.log('Backend connectivity check response:', response.data);
      return Array.isArray(response.data.materials) && response.data.materials.length > 0;
    } catch (error) {
      console.error('Error checking backend connectivity:', error);
      return false;
    }
  };

  useEffect(() => {
    const checkConnectivity = async () => {
      try {
        // Debug logging
        console.log('Checking API connectivity at:', getApiPath('/imaterialise/check'));
        const response = await axios.get(getApiPath('/imaterialise/check'));
        console.log('Backend connectivity check response:', response.data);
        return Array.isArray(response.data.materials) && response.data.materials.length > 0;
      } catch (error) {
        console.error('Error checking backend connectivity:', error);
        console.error('Full error details:', {
          message: error.message,
          response: error.response?.data,
          status: error.response?.status,
          url: error.config?.url
        });
        return false;
      }
    };

    checkConnectivity();
  }, []);

  useEffect(() => {
    const fetchMaterials = async () => {
      setIsMaterialsLoading(true);
      try {
        console.log('Fetching materials from:', getApiPath('/imaterialise/check'));
        const response = await axios.get(getApiPath('/imaterialise/check'));
        console.log('Full materials response:', response);

        if (response.data.materials && Array.isArray(response.data.materials)) {
          // Filter materials to only include silver, brass, and gold
          const filteredMaterials = response.data.materials.filter(material => {
            const materialName = material.label.toLowerCase();
            return materialName.includes('silver') || 
                   materialName.includes('brass') ;
          }).map(material => {
            // Create a copy of the material
            const filteredMaterial = { ...material };
            
            // Filter finishes based on material type
            if (material.label.toLowerCase().includes('silver')) {
              // Only keep "High gloss" and "Antique" finishes for Silver
              filteredMaterial.finishes = material.finishes.filter(finish => 
                finish.label.toLowerCase().includes('high gloss') ||
                finish.label.toLowerCase().includes('antique')
              );
            } else if (material.label.toLowerCase().includes('brass')) {
              filteredMaterial.finishes = material.finishes.filter(finish => 
                finish.label.toLowerCase().includes('red gold plated') ||
                finish.label.toLowerCase().includes('polished gold plated') ||
                finish.label.toLowerCase().includes('rhodium plated polished')
              );
            } else if (material.label.toLowerCase().includes('gold')) {
              // Keep all finishes for Gold
              filteredMaterial.finishes = material.finishes;
            }
            
            return filteredMaterial;
          });
          
          setMaterials(filteredMaterials);
          
          // Find brass material as default
          const brassMaterial = filteredMaterials.find(
            m => m.label.toLowerCase().includes('brass')
          );

          if (brassMaterial) {
            console.log('Setting brass as default material:', brassMaterial);
            setSelectedMaterial(brassMaterial.value);
            // Find polished gold plated finish
            const goldPlatedFinish = brassMaterial.finishes.find(
              f => f.label.toLowerCase().includes('polished gold plated')
            );
            if (goldPlatedFinish) {
              console.log('Setting polished gold plated as default finish:', goldPlatedFinish);
              setSelectedFinish(goldPlatedFinish.value);
            } else {
              setSelectedFinish(brassMaterial.finishes[0].value);
            }
          }
        } else {
          console.warn('No materials array found in response:', response.data);
        }
      } catch (error) {
        console.error('Error fetching materials:', {
          message: error.message,
          status: error.response?.status,
          statusText: error.response?.statusText,
          data: error.response?.data,
          config: {
            url: error.config?.url,
            method: error.config?.method,
            headers: error.config?.headers
          }
        });
      } finally {
        setIsMaterialsLoading(false);
      }
    };

    fetchMaterials();
  }, []);

  // Add effect to reset model info when size changes
  useEffect(() => {
    setIsModelUploaded(false);
    setModelId(null);
    setModelDimensions(null);
    setModelVolume(null);
    setModelSurface(null);
    setModelPrice(null);
  }, [selectedSize]);

  // Add a function to format the price
  const formatPrice = (priceStr) => {
    const [amount, currency] = priceStr.split(' ');
    return `${(parseFloat(amount) * 2).toFixed(2)} ${currency}`;
  };

  // Reset the change tracker when Buy Now link is created
  useEffect(() => {
    if (printLabLink) {
      setHasChangedMaterialOrFinish(false);
    }
  }, [printLabLink]);

  // Update material selection handler
  const handleMaterialChange = async (value) => {
    console.log('Selected material:', value);
    const material = materials.find(m => m.value === value);
    if (material) {
      // First reset the price while we fetch the new one
      setModelPrice(null);
      // Set the default finish for this material
      const defaultFinish = material.finishes[0].value;
      
      // Set material and finish in sequence
      setSelectedMaterial(value);
      setSelectedFinish(defaultFinish);
      setHasChangedMaterialOrFinish(true);
      
      // Update the 3D model material if it exists
      if (objString && !showImage) {
        // Use current values directly instead of state
        const currentMaterial = material;
        const currentFinish = material.finishes[0];
        console.log('Updating 3D model with:', {
          material: currentMaterial.label,
          finish: currentFinish.label
        });
        
        // Pass the current values to loadModel
        loadModel(objString, currentMaterial, currentFinish);
      }
      
      // Only fetch price if we have a model uploaded
      if (modelId && isModelUploaded) {
        try {
          const requestBody = {
            models: [{
              modelID: modelId,
              materialID: value,
              finishID: defaultFinish,
              quantity: "1",
              scale: "1.0"
            }],
            currency: "EUR"
          };

          const response = await axios.post(getApiPath('/imaterialise/get-price'), requestBody);
          if (response.data.models?.[0]?.totalPrice) {
            setModelPrice(`${response.data.models[0].totalPrice} ${response.data.currency}`);
          }
        } catch (error) {
          console.error('Error getting price for new material:', error);
          setError('Failed to get price for selected material');
        }
      }
    }
  };

  // Update finish selection handler
  const handleFinishChange = async (value) => {
    console.log('Selected finish:', value);
    const material = materials.find(m => m.value === selectedMaterial);
    const finish = material?.finishes.find(f => f.value === value);
    
    if (material && finish) {
      setSelectedFinish(value);
      setHasChangedMaterialOrFinish(true);
      setModelPrice(null);
      setIsLoading(true);
      setError(null);

      // Update the 3D model material if it exists
      if (objString && !showImage) {
        // Use current values directly
        console.log('Updating 3D model with:', {
          material: material.label,
          finish: finish.label
        });
        
        // Pass the current values to loadModel
        loadModel(objString, material, finish);
      }

      if (modelId && isModelUploaded) {
        try {
          const requestBody = {
            models: [{
              modelID: modelId,
              materialID: selectedMaterial,
              finishID: value,
              quantity: "1",
              scale: "1.0"
            }],
            currency: "EUR"
          };

          const response = await axios.post(getApiPath('/imaterialise/get-price'), requestBody);
          if (response.data.models?.[0]?.totalPrice) {
            setModelPrice(`${response.data.models[0].totalPrice} ${response.data.currency}`);
          }
        } catch (error) {
          console.error('Error getting price for new finish:', error);
          setError('Failed to get price for selected finish');
        }
      }
      setIsLoading(false);
    }
  };

  // Update date change handler
  const handleDateChange = async (value) => {
    if (value) {  // Only proceed if a date is actually selected
      setSelectedDate(value);
      setDateChanged(true);  // Mark that date has been changed
      setShowImage(false);  // Switch to 3D view when date changes
      
      // Wait for next render cycle before generating
      setTimeout(async () => {
        try {
          await generateEarring();
        } catch (error) {
          console.error('Error generating earring:', error);
          setError('Failed to generate earring');
          setShowImage(true);
          setDateChanged(false);  // Reset date changed state on error
        }
      }, 100);
    }
  };

  // Update size selection to handle both image and 3D model changes
  const handleSizeChange = async (value) => {
    console.log('Size changed to:', value);
    
    // Only regenerate model if date has been changed
    if (dateChanged) {
      setIsLoading(true);
      setShowImage(false);  // Switch to 3D view immediately
      
      // Update size first
      setSelectedSize(value);
      
      // Reset model info
      setIsModelUploaded(false);
      setModelId(null);
      setModelDimensions(null);
      setModelVolume(null);
      setModelSurface(null);
      setModelPrice(null);

      try {
        // Get current material and finish before regenerating
        const currentMaterial = materials.find(m => m.value === selectedMaterial);
        const currentFinish = currentMaterial?.finishes.find(f => f.value === selectedFinish);
        
        // Use the new size value directly instead of relying on state
        const response = await axios.post(getApiPath('/generate-earring'), {
          date: selectedDate.toISOString(),
          withMirror: true,
          size: getBackendSize(value)  // Use value directly instead of selectedSize
        });

        if (response.data.success) {
          setObjString(response.data.objString);
          setIsModelLoading(true);
          // Pass current material and finish to loadModel
          loadModel(response.data.objString, currentMaterial, currentFinish);
        } else {
          throw new Error(response.data.error || 'Unknown error');
        }
      } catch (error) {
        console.error('Error regenerating model with new size:', error);
        setError('Failed to update model with new size');
        setShowImage(true);  // Fallback to image view on error
      } finally {
        setIsLoading(false);
      }
    } else {
      // If date hasn't been changed, just update size and show the new size image
      setSelectedSize(value);
      // Reset model info
      setIsModelUploaded(false);
      setModelId(null);
      setModelDimensions(null);
      setModelVolume(null);
      setModelSurface(null);
      setModelPrice(null);
      setShowImage(true);
    }
  };

  // Update the useEffect for scene initialization
  useEffect(() => {
    if (!showImage) {  // Only initialize scene when showing 3D model
      const initialized = initScene();
      if (!initialized) return;

      // Animation loop
      let animationFrameId;
      const animate = () => {
        animationFrameId = requestAnimationFrame(animate);
        if (controlsRef.current) {
          controlsRef.current.update();
        }
        if (rendererRef.current && sceneRef.current && cameraRef.current) {
          rendererRef.current.render(sceneRef.current, cameraRef.current);
        }
      };

      animate();

      // Handle window resize
      const handleResize = () => {
        if (!mountRef.current || !rendererRef.current || !cameraRef.current) return;

        const width = mountRef.current.clientWidth;
        const height = mountRef.current.clientHeight;
        
        cameraRef.current.aspect = width / height;
        cameraRef.current.updateProjectionMatrix();
        rendererRef.current.setSize(width, height);
      };

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
        cancelAnimationFrame(animationFrameId);
        if (rendererRef.current) {
          if (mountRef.current?.contains(rendererRef.current.domElement)) {
            mountRef.current.removeChild(rendererRef.current.domElement);
          }
          rendererRef.current.dispose();
          rendererRef.current = null;
        }
        if (controlsRef.current) {
          controlsRef.current.dispose();
          controlsRef.current = null;
        }
        if (sceneRef.current) {
          while(sceneRef.current.children.length > 0) { 
            sceneRef.current.remove(sceneRef.current.children[0]); 
          }
        }
      };
    }
  }, [showImage]);

  return (
    // <Container size="xl">
      <Paper shadow="md" p="2" radius="md" withBorder>
        <Title order={2} align="center" mb="xl" sx={(theme) => ({
          color: theme.colors.blue[7]
        })}>
          Spiral Earring Model
        </Title>

        <Stack spacing="xl">
          <Card shadow="sm" p="xs" radius="md" withBorder>
            <Stack spacing="md">
              <div style={{ position: 'relative' }}>
                <Select
                  label="Select Size"
                  placeholder="Choose a size"
                  data={[
                    { value: 'regular', label: 'Regular (35mm height + hook)' },  // Will map to 'small' in backend
                    { value: 'small', label: 'Small (25mm height + hook)' }       // Will map to 'micro' in backend
                  ]}
                  value={selectedSize}
                  onChange={handleSizeChange}
                  size="md"
                  radius="md"
                  style={{ width: '100%' }}
                  disabled={isLoading}
                />
                <Text size="xs" color="dimmed" mt={4} sx={{ opacity: 0.7 }}>
                  Note: Size measurements indicate the earring height without the hook
                </Text>
              </div>
              <div style={{ position: 'relative' }}>
                <DatePickerInput
                  label="Select a date for the model"
                  value={selectedDate}
                  onChange={handleDateChange}
                  size="md"
                  radius="md"
                  style={{ width: '100%' }}
                  disabled={isLoading}
                  rightSection={isLoading ? <Loader size="xs" /> : null}
                />
              </div>
            </Stack>
          </Card>

          {/* Image/3D viewer container */}
          <div style={{ 
            borderRadius: '8px', 
            marginBottom: '16px',
            minHeight: '500px',
            backgroundColor: '#ffffff',
            border: isModelUploaded ? '2px solid #40c057' : '1px solid #dee2e6',
            overflow: 'hidden',
            position: 'relative'
          }}>
            {showImage ? (
              <div style={{
                width: '100%',
                height: '500px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#ffffff',
                overflow: 'hidden'
              }}>
                <div style={{
                  width: '100%',
                  height: '100%',
                  position: 'relative',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                  <img
                    key={selectedSize}
                    src={selectedSize === 'regular' ? regularEarringImg : smallEarringImg}
                    alt={`${selectedSize === 'regular' ? 'Regular' : 'Small'} Spiral Earring`}
                    style={{
                      maxWidth: '80%',
                      maxHeight: '80%',
                      objectFit: 'contain',
                      transition: 'opacity 0.3s ease-in-out'
                    }}
                  />
                </div>
              </div>
            ) : (
              <div
                ref={mountRef}
                style={{
                  width: '100%',
                  height: '500px',
                  position: 'relative',
                  overflow: 'hidden',
                  borderRadius: '8px',
                  backgroundColor: '#ffffff'
                }}
              >
                {isModelLoading && (
                  <Center style={{ 
                    height: '100%', 
                    backgroundColor: 'rgba(255,255,255,0.8)',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: 10
                  }}>
                    <Stack align="center" spacing="xs">
                      <Loader size="xl" variant="dots" />
                      <Text size="sm" color="dimmed">Loading 3D Model...</Text>
                    </Stack>
                  </Center>
                )}
              </div>
            )}
          </div>

          {/* Material selector - show after model is generated */}
          {objString && (
            <Card shadow="sm" p="md" radius="md" withBorder style={{ position: 'relative' }}>
              <Stack spacing="md">
                <Button
                  onClick={uploadModel}
                  disabled={isLoading || !objString || isModelUploaded}
                  variant={isModelUploaded ? "outline" : "filled"}
                  color={isModelUploaded ? "teal" : "blue"}
                  fullWidth
                  size="md"
                  style={{ 
                    borderColor: isModelUploaded ? '#40c057' : undefined,
                    color: isModelUploaded ? '#40c057' : undefined
                  }}
                >
                  {isModelUploaded ? 'Model Is Uploaded ✓' : 'Upload Model To Shop'}
                </Button>

                {isLoading && (
                  <Center style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '100%',
                    height: '100%',
                    background: 'rgba(255, 255, 255, 0.7)',
                    backdropFilter: 'blur(2px)',
                    zIndex: 10
                  }}>
                    <Stack align="center" spacing="md">
                      <Loader size="xl" variant="dots" color="blue" />
                      <Text size="md" weight={500} color="dimmed">Loading...</Text>
                    </Stack>
                  </Center>
                )}

                {isModelUploaded && (
                  <Text size="sm" color="dimmed">
                    Next: Select material and finish below
                  </Text>
                )}

                {materials.length > 0 && (
                  <>
                    <Select
                      label="Select Material"
                      placeholder={isMaterialsLoading ? "Loading materials..." : "Choose a material"}
                      data={materials.map(m => ({ value: m.value, label: m.label }))}
                      value={selectedMaterial}
                      defaultValue={materials.find(m => m.label?.toLowerCase()?.includes('silver'))?.value}
                      disabled={isLoading || !isModelUploaded || isMaterialsLoading}
                      onChange={handleMaterialChange}
                      searchable
                      nothingFound="No materials found"
                      maxDropdownHeight={400}
                      sx={{ minWidth: 250 }}
                      rightSection={isMaterialsLoading ? <Loader size="xs" /> : null}
                    />
                    {selectedMaterial && (
                      <>
                        <Select
                          label="Select Finish"
                          placeholder={isMaterialsLoading ? "Loading finishes..." : "Choose a finish"}
                          data={materials.find(m => m.value === selectedMaterial)?.finishes?.map(f => ({ 
                            value: f.value, 
                            label: f.label 
                          })) || []}
                          value={selectedFinish}
                          disabled={isLoading || !isModelUploaded || isMaterialsLoading}
                          onChange={handleFinishChange}
                          searchable
                          nothingFound="No finishes found"
                          maxDropdownHeight={400}
                          sx={{ minWidth: 250 }}
                          rightSection={isMaterialsLoading ? <Loader size="xs" /> : null}
                        />
                        <Text size="sm" color="dimmed">
                          Material: {materials.find(m => m.value === selectedMaterial)?.label}
                          <br />
                          Finish: {materials.find(m => m.value === selectedMaterial)?.finishes?.find(f => f.value === selectedFinish)?.label}
                          <br />
                          Production time: {materials.find(m => m.value === selectedMaterial)?.finishes?.find(f => f.value === selectedFinish)?.productionTime || '3-5'} business days
                        </Text>
                      </>
                    )}
                  </>
                )}
              </Stack>
            </Card>
          )}

          {/* Price and Cart button */}
          {isModelUploaded && selectedMaterial && selectedFinish && (
            <Card shadow="sm" p="md" radius="md" withBorder>
              <Stack spacing="xs">
                {modelPrice ? (
                  <>
                    <Text weight={500} size="lg" align="center">
                      Price: {formatPrice(modelPrice)}
                    </Text>
                    <Button
                      onClick={createCart}
                      radius="md"
                      leftIcon={!isLoading && <IconShoppingCart size={16} />}
                      disabled={isLoading || !selectedMaterial || !selectedFinish || (printLabLink && !hasChangedMaterialOrFinish)}
                      fullWidth
                      size="md"
                      sx={(theme) => ({
                        background: theme.colors.green[6],
                        '&:hover': {
                          background: theme.colors.green[7],
                        }
                      })}
                    >
                      {isLoading ? <Loader size="sm" color="white" /> : 'Add to Cart'}
                    </Button>
                  </>
                ) : (
                  <Center>
                    <Loader size="sm" />
                  </Center>
                )}
              </Stack>
            </Card>
          )}

          {/* Buy Now button */}
          {printLabLink && (
            <Button 
              component="a" 
              href={printLabLink} 
              target="_blank" 
              rel="noopener noreferrer"
              radius="md"
              size="md"
              fullWidth
              disabled={isLoading}
              sx={(theme) => ({
                background: theme.colors.violet[6],
                '&:hover': {
                  background: theme.colors.violet[7],
                }
              })}
            >
              Buy Now
            </Button>
          )}

          {/* Only show model information if model is uploaded */}
          {isModelUploaded && modelDimensions && modelVolume && modelSurface && (
            <Card shadow="sm" p="md" radius="md" withBorder>
              <Stack spacing="md">
                <Text size="sm" weight={500}>Model Information</Text>
                <Text size="sm" color="dimmed">
                  Dimensions: {modelDimensions ? `${modelDimensions.x.toFixed(3)} x ${modelDimensions.y.toFixed(3)} x ${modelDimensions.z.toFixed(3)} mm` : 'Not available'}
                </Text>
                <Text size="sm" color="dimmed">
                  Volume: {modelVolume ? `${modelVolume.toFixed(6)} cm³` : 'Not available'}
                </Text>
                <Text size="sm" color="dimmed">
                  Surface area: {modelSurface ? `${modelSurface.toFixed(4)} cm²` : 'Not available'}
                </Text>
                <Text size="sm" color="dimmed">
                  Valid until: {new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toLocaleDateString()}
                </Text>
                {modelId && (
                  <Text size="sm" color="dimmed">
                    The model needs some automatic repairs before printing. This is normal and will be handled by i.materialise.
                  </Text>
                )}
              </Stack>
            </Card>
          )}

          {error && (
            <Text color="red" size="sm" align="center">
              {error}
            </Text>
          )}
        </Stack>
      </Paper>
    // </Container>
  );
};

export default ShopM;