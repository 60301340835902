// src/App.js
import React, { useState, useEffect, useCallback } from 'react';
import { MantineProvider, Container, LoadingOverlay } from '@mantine/core';
import { Elements } from '@stripe/react-stripe-js';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Notifications } from '@mantine/notifications';
import stripePromise from './stripe';
import LandingTabs from './components/LandingTabs';
import ShopM from './components/ShopM';
import ShopS from './components/ShopS';
import Auth0Callback from './components/Auth0Callback';
import SubscriptionSuccess from './components/SubscriptionSuccess';
import axios from './axiosConfig';
import { CountryProvider } from './contexts/CountryContext';

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [subscriptionStatus, setSubscriptionStatus] = useState('none');

  const fetchUserData = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        setLoading(false);
        return;
      }

      const response = await axios.get('/auth/me');
      setUser(response.data);
      setSubscriptionStatus(response.data?.subscription_status || 'none');
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.log('Auth check failed:', error.message);
      }
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  const handleLogin = (data) => {
    console.log('Login data received:', data);
    setUser(data.user);
    setSubscriptionStatus(data.user?.subscription_status || 'none');
    localStorage.setItem('token', data.token);
    axios.defaults.headers.common['Authorization'] = `Bearer ${data.token}`;
  }

  const handleLogout = () => {
    setUser(null);
    setSubscriptionStatus('none');
    localStorage.removeItem('token');
    delete axios.defaults.headers.common['Authorization'];
  };

  return (
    <CountryProvider>
      {loading ? (
        <LoadingOverlay visible />
      ) : (
        <MantineProvider withGlobalStyles withNormalizeCSS>
          <Notifications position="top-right" zIndex={3000} />
          <Elements stripe={stripePromise}>
            <Router>
              <Container size="xl" px="xs">
                <Routes>
                  <Route path="/" element={
                    <LandingTabs 
                      user={user} 
                      onLogin={handleLogin} 
                      onLogout={handleLogout}
                      subscriptionStatus={subscriptionStatus}
                    />
                  } />
                  <Route path="/callback" element={<Auth0Callback />} />
                  <Route path="/shop-m" element={<ShopM user={user} />} />
                  <Route path="/shop-s" element={<ShopS user={user} />} />
                  <Route 
                    path="/subscription/success" 
                    element={
                      <SubscriptionSuccess 
                        onClose={() => {
                          fetchUserData();
                          window.location.href = '/';
                        }}
                      />
                    } 
                  />
                </Routes>
              </Container>
            </Router>
          </Elements>
        </MantineProvider>
      )}
    </CountryProvider>
  );
}

export default App;
