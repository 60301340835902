// src/components/Coordinates.js
import React, { useState, useEffect, useCallback } from 'react';
import { Box, Select, NumberInput } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { IconX } from '@tabler/icons-react';
import { useCountryContext } from '../contexts/CountryContext';
import axios from 'axios';

function Coordinates({ initialCountry, initialCity, initialLongitude, initialLatitude, onCoordinatesChange }) {
  const { countries, loading, error: countryError } = useCountryContext();
  console.log('CountryContext state:', { 
    countriesCount: countries?.length, 
    loading, 
    error: countryError 
  });

  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(initialCountry || '');
  const [selectedCity, setSelectedCity] = useState(initialCity || '');
  const [longitude, setLongitude] = useState(initialLongitude || '');
  const [latitude, setLatitude] = useState(initialLatitude || '');

  const fetchCities = useCallback(async (countryCode) => {
    try {
      const country = countries.find(c => c.value === countryCode);
      if (!country) {
        console.error('Country not found:', countryCode);
        return;
      }

      console.log('Fetching cities for country:', country.label);
      
      const response = await axios.get(
        `https://countriesnow.space/api/v0.1/countries/cities/q`,
        {
          params: {
            country: country.label
          }
        }
      );

      console.log('Cities API Response:', response);
      
      if (response.data && response.data.data) {
        const cityList = response.data.data.map(city => ({
          value: city,
          label: city
        }));
        console.log('Processed cities:', cityList.length);
        setCities(cityList);
      }
      
      return response.data;
    } catch (error) {
      console.error('Cities API Error:', error.response?.data || error.message);
      showNotification({
        title: 'Error',
        message: error.response?.data?.msg || 'Failed to fetch cities',
        color: 'red',
        icon: <IconX size={18} />,
      });
      setCities([]);
      throw error;
    }
  }, [countries]);

  const fetchCoordinates = useCallback(async (city, country) => {
    try {
      console.log('Fetching coordinates for:', { city, country });
      
      const response = await axios.get(
        `https://nominatim.openstreetmap.org/search`,
        {
          params: {
            q: `${city},${country}`,
            format: 'json',
            limit: 1
          }
        }
      );

      console.log('Coordinates API Response:', response);

      if (response.data && response.data.length > 0) {
        const { lat, lon } = response.data[0];
        console.log('Found coordinates:', { lat, lon });
        setLatitude(parseFloat(lat));
        setLongitude(parseFloat(lon));
        onCoordinatesChange(parseFloat(lat), parseFloat(lon));
      } else {
        console.log('No coordinates found for location:', { city, country });
        showNotification({
          title: 'Location Not Found',
          message: `Could not find coordinates for ${city}, ${country}`,
          color: 'yellow',
          icon: <IconX size={18} />,
        });
      }
    } catch (error) {
      console.error('Coordinates API Error:', {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status
      });
      
      showNotification({
        title: 'Error',
        message: `Failed to fetch coordinates: ${error.message}`,
        color: 'red',
        icon: <IconX size={18} />,
      });
    }
  }, [onCoordinatesChange]);

  const handleCountryChange = useCallback((value) => {
    console.log('handleCountryChange called with:', value);
    setSelectedCountry(value);
    setSelectedCity('');
    
    if (value) {
      fetchCities(value);
    } else {
      setCities([]);
    }
  }, [fetchCities]);

  const handleCityChange = useCallback((value) => {
    console.log('City selected:', value);
    setSelectedCity(value);
    if (value && selectedCountry) {
      const country = countries.find(c => c.value === selectedCountry);
      fetchCoordinates(value, country?.label);
    }
  }, [selectedCountry, countries, fetchCoordinates]);

  useEffect(() => {
    if (initialCountry) {
      handleCountryChange(initialCountry);
    }
  }, [initialCountry, handleCountryChange]);

  return (
    <Box>
      <Select
        label="Country"
        placeholder="Select a country"
        data={countries}
        value={selectedCountry}
        onChange={handleCountryChange}
        searchable
        nothingFound="No country found"
        maxDropdownHeight={280}
        disabled={loading}
        error={countryError}
        withinPortal
        zIndex={1000}
      />
      <Select
        label="City"
        placeholder="Select a city"
        data={cities}
        value={selectedCity}
        onChange={handleCityChange}
        searchable
        nothingFound="No city found"
        maxDropdownHeight={280}
        disabled={!selectedCountry}
        withinPortal
        zIndex={1000}
      />
      <NumberInput
        label="Longitude"
        placeholder="Longitude"
        precision={6}
        value={longitude}
        onChange={(value) => {
          setLongitude(value);
          onCoordinatesChange(latitude, value);
        }}
        hideControls
        readOnly={!longitude}
      />
      <NumberInput
        label="Latitude"
        placeholder="Latitude"
        precision={6}
        value={latitude}
        onChange={(value) => {
          setLatitude(value);
          onCoordinatesChange(value, longitude);
        }}
        hideControls
        readOnly={!latitude}
      />
    </Box>
  );
}

export default Coordinates;