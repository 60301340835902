// src/contexts/CountryContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

const CountryContext = createContext();

export function CountryProvider({ children }) {
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get('https://countriesnow.space/api/v0.1/countries', {
          headers: {
            'Content-Type': 'application/json',
            'Accept': '*/*'
          }
        });

        if (response.data && response.data.data) {
          const formattedCountries = response.data.data.map(country => ({
            value: country.iso2,
            label: country.country
          }));
          
          setCountries(formattedCountries);
          setError(null);
        }
      } catch (error) {
        console.error('Failed to fetch countries:', error);
        setError('Failed to load countries');
        setCountries([]);
      } finally {
        setLoading(false);
      }
    };

    fetchCountries();
  }, []);

  const value = {
    countries,
    loading,
    error,
    refreshCountries: () => setLoading(true)
  };

  return (
    <CountryContext.Provider value={value}>
      {children}
    </CountryContext.Provider>
  );
}

export function useCountryContext() {
  const context = useContext(CountryContext);
  if (!context) {
    throw new Error('useCountryContext must be used within a CountryProvider');
  }
  return context;
}

export default CountryContext;