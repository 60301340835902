// src/axiosConfig.js
import axios from 'axios';

const baseURL = process.env.NODE_ENV === 'production' 
  ? 'https://f-oracle-backend.onrender.com'
  : 'http://localhost:10000';

const instance = axios.create({
  baseURL,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
  }
});

// Add request interceptor
instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    console.log('Making request:', {
      url: config.url,
      baseURL: config.baseURL,
      headers: config.headers
    });
    return config;
  },
  (error) => Promise.reject(error)
);

// Add response interceptor for better error logging
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error('Request failed:', {
      url: error.config.url,
      method: error.config.method,
      status: error.response?.status,
      data: error.response?.data
    });
    return Promise.reject(error);
  }
);

// Add interceptor to handle authentication errors silently for certain endpoints
instance.interceptors.response.use(
  response => response,
  error => {
    const publicEndpoints = [
      '/api/collective-horoscopes',
      // Add other public endpoints here
    ];
    
    // Don't show errors for public endpoints
    if (error.response?.status === 401 && 
        publicEndpoints.some(endpoint => error.config.url.includes(endpoint))) {
      return Promise.resolve({ data: [] }); // Return empty data instead of error
    }
    
    return Promise.reject(error);
  }
);

export default instance;