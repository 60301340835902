import React, { useState, useEffect } from 'react';
import { Box, Title, Text, Button, Group, Alert, TextInput, PasswordInput, Stack, LoadingOverlay, Tabs, Paper, Popover, Tooltip } from '@mantine/core';
import { DateTimePicker, DatePicker } from '@mantine/dates';
import { IconCheck, IconAlertCircle, IconX, IconInfoCircle, IconTrash, IconCalendar, IconRefresh } from '@tabler/icons-react';
import { showNotification, updateNotification } from '@mantine/notifications';
import axios from '../axiosConfig';
import Coordinates from './Coordinates';
import { formatDateTime, formatHoroscopeType } from '../utils/dateUtils';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip as RechartsTooltip, ResponsiveContainer, Cell } from 'recharts';
import { format } from 'date-fns';

function UserSettings({ user, onLogout, onUserUpdate }) {
  const [formData, setFormData] = useState({
    userName: '',
    email: '',
    password: '',
    confirmPassword: '',
    longitude: '',
    latitude: '',
    birthDatetime: null,
  });
  
  const [loading, setLoading] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState('none');
  const [generatedHoroscopes, setGeneratedHoroscopes] = useState({});
  const [activeHoroscopeType, setActiveHoroscopeType] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [loadingHoroscopes, setLoadingHoroscopes] = useState(false);
  const [datePickerOpened, setDatePickerOpened] = useState(false);
  const [populatedDates, setPopulatedDates] = useState([]);

  useEffect(() => {
    if (user) {
      setFormData({
        userName: user.username || '',
        email: user.email || '',
        password: '',
        confirmPassword: '',
        longitude: user.longitude || '',
        latitude: user.latitude || '',
        birthDatetime: user.birth_datetime ? new Date(user.birth_datetime) : null,
      });
      
      // Set initial date to start of today in UTC
      const today = new Date();
      const utcToday = new Date(Date.UTC(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        0, 0, 0, 0
      ));
      setSelectedDate(utcToday);
      // Fetch horoscopes for the current date when component mounts
      fetchHoroscopesForDate(utcToday);
    }
  }, [user]);

  useEffect(() => {
    const fetchSubscriptionStatus = async () => {
      try {
        const response = await axios.get('/api/users/subscription-status');
        setSubscriptionStatus(response.data.status || 'none');
      } catch (error) {
        console.error('Failed to fetch subscription status:', error);
      }
    };

    if (user) {
      fetchSubscriptionStatus();
    }
  }, [user]);

  // Format horoscope type for display
  const formatHoroscopeTypeShort = (type) => {
    const typeMap = {
      'collective_daily': 'Daily',
      'collective_weekly': 'Weekly',
      'collective_monthly': 'Monthly',
      'politics': 'Politics',
      'warnings': 'Warnings',
      'opportunities': 'Opportunities',
      'travel': 'Travel'
    };
    return typeMap[type] || type;
  };

  // Fetch populated dates for the current month
  const fetchPopulatedDates = async (month = new Date()) => {
    try {
      const startDate = new Date(month.getFullYear(), month.getMonth(), 1);
      const endDate = new Date(month.getFullYear(), month.getMonth() + 1, 0);
      
      const response = await axios.get('/api/collective-horoscopes/populated-dates', {
        params: {
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString()
        }
      });
      
      setPopulatedDates(response.data);
    } catch (error) {
      console.error('Error fetching populated dates:', error);
      setPopulatedDates([]);
    }
  };

  // Function to check if a date has data and get its types
  const getDateData = (date) => {
    return populatedDates.find(pd => {
      const pdDate = new Date(pd.date);
      return pdDate.getDate() === date.getDate() &&
             pdDate.getMonth() === date.getMonth() &&
             pdDate.getFullYear() === date.getFullYear();
    });
  };

  // Function to get day props for calendar
  const getDayProps = (date) => {
    const dateData = getDateData(date);
    if (!dateData) return {};

    const tooltipContent = (
      <div>
        <Text size="sm" weight={500} mb={5}>Available Horoscopes:</Text>
        {dateData.types.map((type, index) => (
          <Text key={type} size="sm" color="dimmed">
            • {formatHoroscopeTypeShort(type)}
          </Text>
        ))}
      </div>
    );

    return {
      style: {
        backgroundColor: '#e9ecef',
        color: '#1a1b1e',
        position: 'relative',
        cursor: 'pointer'
      },
      sx: (theme) => ({
        '&:hover': {
          backgroundColor: theme.fn.lighten('#e9ecef', 0.1),
        },
        '&::after': {
          content: '""',
          display: 'block',
          width: '4px',
          height: '4px',
          borderRadius: '50%',
          backgroundColor: theme.colors.violet[6],
          position: 'absolute',
          bottom: 4,
          left: '50%',
          transform: 'translateX(-50%)'
        }
      }),
      component: (props) => (
        <Tooltip
          label={tooltipContent}
          position="bottom"
          withArrow
          multiline
          width={200}
        >
          <div {...props} />
        </Tooltip>
      )
    };
  };

  // Update populated dates when component mounts
  useEffect(() => {
    fetchPopulatedDates();
  }, []);

  const handleSubscribe = async () => {
    try {
      setLoading(true);
      console.log('Starting subscription process');
      
      const response = await axios.post('/api/users/create-subscription');

      console.log('Checkout session created:', response.data);
      
      if (response.data.url) {
        window.location.href = response.data.url;
      } else {
        throw new Error('No checkout URL received');
      }
    } catch (error) {
      console.error('Subscription failed:', error);
      showNotification({
        title: 'Error',
        message: 'Failed to start subscription process: ' + (error.response?.data?.message || error.message),
        color: 'red',
        icon: <IconX size={18} />,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (field) => (event) => {
    setFormData({ ...formData, [field]: event.target.value });
  };

  const handleDateTimeChange = (value) => {
    setFormData({ ...formData, birthDatetime: value });
  };

  const handleCoordinatesChange = (latitude, longitude) => {
    setFormData(prevData => ({ ...prevData, latitude, longitude }));
  };

  const handleUpdate = async () => {
    if (formData.password && formData.password !== formData.confirmPassword) {
      showNotification({
        message: 'Passwords do not match',
        color: 'red',
        icon: <IconX size={18} />,
      });
      return;
    }
  
    try {
      setLoading(true);
      const updateData = {};
  
      // Only include fields that have changed
      if (formData.userName !== user.username) updateData.username = formData.userName;
      if (formData.email !== user.email) updateData.email = formData.email;
      if (formData.password) updateData.password = formData.password;
      if (formData.longitude !== user.longitude) updateData.longitude = formData.longitude ? parseFloat(formData.longitude) : null;
      if (formData.latitude !== user.latitude) updateData.latitude = formData.latitude ? parseFloat(formData.latitude) : null;
      if (formData.birthDatetime !== user.birth_datetime) updateData.birth_datetime = formData.birthDatetime ? formData.birthDatetime.toISOString() : null;
  
      console.log('Sending update data:', updateData);
  
      const response = await axios.put(`/users/${user.id}`, updateData);
  
      console.log('Update response:', response.data);
  
      showNotification({
        message: 'Update successful',
        color: 'teal',
        icon: <IconCheck size={18} />,
      });
  
      onUserUpdate(response.data);
    } catch (error) {
      console.error('Update failed', error.response || error);
      showNotification({
        message: `Update failed: ${error.response?.data?.message || error.message}`,
        color: 'red',
        icon: <IconX size={18} />,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleGenerateData = async () => {
    try {
      setLoading(true);
      // Use the selected date in UTC
      const utcDate = new Date(Date.UTC(
        selectedDate.getFullYear(),
        selectedDate.getMonth(),
        selectedDate.getDate(),
        0, 0, 0, 0
      ));

      console.log('Generating data for date:', utcDate.toISOString());

      const types = [
        'collective_daily',
        'collective_weekly',
        'collective_monthly',
        'politics',
        'warnings',
        'opportunities',
        'travel'
      ];

      const generated = [];
      const skipped = [];

      for (const type of types) {
        try {
          showNotification({
            id: `loading-${type}`,
            title: `Generating ${formatHoroscopeType(type)}`,
            message: 'Please wait...',
            loading: true,
            autoClose: false
          });

          const response = await axios.post('/api/collective-horoscopes/generate', {
            type,
            date: utcDate.toISOString()
          });

          if (response.data) {
            generated.push(type);
            updateNotification({
              id: `loading-${type}`,
              title: `Generated ${formatHoroscopeType(type)}`,
              message: 'Successfully generated horoscope',
              color: 'teal',
              icon: <IconCheck />,
              autoClose: 2000
            });
          }
        } catch (error) {
          console.error(`Failed to generate ${type}:`, error);
          updateNotification({
            id: `loading-${type}`,
            color: 'red',
            title: `Failed to generate ${formatHoroscopeType(type)}`,
            message: error.response?.data?.error || error.message,
            icon: <IconX />,
            autoClose: 5000
          });
          skipped.push(`${type} (${error.response?.data?.error || error.message})`);
        }
      }

      // After generating all horoscopes, fetch them to update the display
      await fetchHoroscopesForDate(utcDate);
      
      // Refresh the populated dates to update the calendar
      await fetchPopulatedDates(utcDate);

      showNotification({
        id: 'generation-summary',
        title: 'Generation Complete',
        message: `Generated ${generated.length} horoscopes${skipped.length > 0 ? ` and skipped ${skipped.length} types` : ''}.`,
        color: 'blue',
        icon: <IconInfoCircle />,
        autoClose: 5000
      });
    } catch (error) {
      console.error('Generation failed:', error);
      showNotification({
        title: 'Error',
        message: 'Failed to generate horoscopes',
        color: 'red',
        icon: <IconX />,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveHoroscope = async (type, data) => {
    try {
      if (!data.input_date || !type) {
        throw new Error('Missing required data for deletion');
      }

      // Ensure we're using UTC date for consistency
      const utcDate = new Date(data.input_date);
      utcDate.setUTCHours(0, 0, 0, 0);

      console.log('Removing horoscope:', { 
        horoscope_type: type, 
        input_date: utcDate.toISOString() 
      });

      // Use the collective-horoscopes endpoint with query parameters
      await axios.delete('/api/collective-horoscopes', {
        params: {
          horoscope_type: type,
          input_date: utcDate.toISOString()
        }
      });

      // Remove from local state
      const newHoroscopes = { ...generatedHoroscopes };
      delete newHoroscopes[type];
      setGeneratedHoroscopes(newHoroscopes);

      // Update active type if needed
      if (activeHoroscopeType === type) {
        const remainingTypes = Object.keys(newHoroscopes);
        setActiveHoroscopeType(remainingTypes.length > 0 ? remainingTypes[0] : null);
      }

      showNotification({
        title: 'Horoscope Removed',
        message: `Successfully removed ${formatHoroscopeType(type)} horoscope for ${formatDateTime(data.input_date)}`,
        color: 'teal',
        icon: <IconCheck />,
      });

      // Refresh the horoscopes for the current date
      await fetchHoroscopesForDate(selectedDate);
    } catch (error) {
      console.error('Failed to remove horoscope:', error);
      showNotification({
        title: 'Error',
        message: `Failed to remove horoscope: ${error.response?.data?.error || error.message}`,
        color: 'red',
        icon: <IconX />,
      });
    }
  };

  const handleDateChange = async (date) => {
    // Ensure the date is set to start of day in UTC
    const utcDate = new Date(Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      0, 0, 0, 0
    ));
    setSelectedDate(utcDate);
    await fetchHoroscopesForDate(utcDate);
  };

  const fetchHoroscopesForDate = async (date) => {
    try {
      setLoadingHoroscopes(true);
      const horoscopeTypes = [
        'collective_daily',
        'collective_weekly',
        'collective_monthly',
        'politics',
        'warnings',
        'opportunities',
        'travel'
      ];

      const horoscopesByType = {};
      
      // Ensure we're using UTC date
      const utcDate = new Date(Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        0, 0, 0, 0
      ));
      
      // Fetch each type of horoscope for the selected date
      for (const type of horoscopeTypes) {
        try {
          const response = await axios.get('/api/collective-horoscopes', {
            params: {
              type,
              date: utcDate.toISOString()
            }
          });

          // If we have data and it has an id, store it
          if (response.data && response.data.length > 0) {
            const horoscope = response.data[0];
            if (horoscope.id) {
              console.log(`Found horoscope for ${type}:`, horoscope);
              horoscopesByType[type] = {
                ...horoscope,
                input_date: horoscope.input_date || utcDate.toISOString(),
                horoscope_type: type
              };
            }
          }
        } catch (error) {
          console.error(`Failed to fetch ${type} horoscope:`, error);
        }
      }

      console.log('All horoscopes:', horoscopesByType);
      setGeneratedHoroscopes(horoscopesByType);
      
      const foundTypes = Object.keys(horoscopesByType);
      if (foundTypes.length > 0) {
        setActiveHoroscopeType(foundTypes[0]);
      } else {
        setActiveHoroscopeType(null);
      }
    } catch (error) {
      console.error('Failed to fetch horoscopes:', error);
      showNotification({
        title: 'Error',
        message: 'Failed to fetch horoscopes for selected date',
        color: 'red',
        icon: <IconX />,
      });
    } finally {
      setLoadingHoroscopes(false);
    }
  };

  const formatDateTime = (dateString) => {
    if (!dateString) return 'Not available';
    const date = new Date(dateString);
    return format(date, 'MMMM d, yyyy');
  };

  const formatHoroscopeType = (type) => {
    return type
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const barColors = ['#FF6B6B', '#4ECDC4', '#45B7D1', '#96CEB4', '#FFEEAD', '#D4A5A5', '#9FA8DA'];

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <Box style={{ 
          backgroundColor: 'white', 
          padding: '10px', 
          border: '1px solid #ccc',
          borderRadius: '4px'
        }}>
          <Text>{payload[0].payload.attribute}: {payload[0].value}%</Text>
        </Box>
      );
    }
    return null;
  };

  const extractPercentages = (chatbotResponse) => {
    if (!chatbotResponse) return [];
    
    // Default attributes we're looking for
    const attributes = ['Intuition', 'Energy', 'Luck'];
    
    // Map to handle both English and Swedish terms
    const attributeMap = {
      'Intuition': 'Intuition',
      'Energy': 'Energi',
      'Luck': 'Tur'
    };
    
    // First try to find the numerical values section
    const numericalSection = chatbotResponse.split('Numerical Values:')[1];
    if (numericalSection) {
      return attributes.map(attribute => {
        const pattern = new RegExp(`${attribute}:\\s*(\\d+)%`);
        const match = numericalSection.match(pattern);
        return {
          attribute,
          value: match ? parseInt(match[1], 10) : 0
        };
      });
    }
    
    // Fallback to searching in the entire text
    return attributes.map(attribute => {
      // Try different patterns for both English and Swedish terms
      const patterns = [
        new RegExp(`-\\s*${attribute}:\\s*(\\d+)`),
        new RegExp(`${attribute}:\\s*(\\d+)`),
        new RegExp(`-\\s*${attributeMap[attribute]}:\\s*(\\d+)`),
        new RegExp(`${attributeMap[attribute]}:\\s*(\\d+)`)
      ];
      
      for (const pattern of patterns) {
        const match = chatbotResponse.match(pattern);
        if (match) {
          return {
            attribute,
            value: parseInt(match[1], 10)
          };
        }
      }
      
      return {
        attribute,
        value: 0
      };
    });
  };

  const formatChatbotResponse = (response) => {
    if (!response) return null;
    
    try {
      // Add note about weighted sorting if present
      let formattedSections = [];
      if (response.includes('aspects') || response.includes('fundamentals')) {
        formattedSections.push(
          <Text key="weighted-note" mb={16} italic>
            The following astrological elements are sorted by significance, with the most influential aspects and fundamentals listed first.
          </Text>
        );
      }

      // Split into sections if present
      const sections = response.split(/\[.*?\]/).filter(Boolean);
      const headers = response.match(/\[.*?\]/g) || [];
      
      if (sections.length > 1) {
        // Format each section with its header
        sections.forEach((section, index) => {
          const header = headers[index];
          const content = section.trim();
          
          if (header) {
            // Remove square brackets and format as Title
            const cleanHeader = header.replace(/[\[\]]/g, '');
            formattedSections.push(
              <Title key={`header-${index}`} order={4} mt={24} mb={16} color="indigo">
                {cleanHeader}
              </Title>
            );
          }
          
          // Handle fundamentals sections with special formatting
          if (content.includes('Elements:') || content.includes('Modalities:') || content.includes('Energies:')) {
            const parts = content.split(/(?=Elements:|Modalities:|Energies:)/);
            parts.forEach((part, partIndex) => {
              if (part.trim()) {
                const [subHeader, ...details] = part.split(':');
                formattedSections.push(
                  <Text key={`fundamental-${index}-${partIndex}`} mb={12}>
                    <Text component="span" weight={700} color="indigo">{subHeader}:</Text>
                    <Text component="span">{details.join(':')}</Text>
                  </Text>
                );
              }
            });
          } else {
            // Handle regular content and aspects
            const lines = content.split('\n').filter(line => line.trim());
            lines.forEach((line, lineIndex) => {
              if (line.includes(':')) {
                const [key, ...valueParts] = line.split(':');
                const value = valueParts.join(':').trim();
                formattedSections.push(
                  <Text key={`line-${index}-${lineIndex}`} mb={12}>
                    <Text component="span" weight={700} color="indigo">{key}:</Text>
                    <Text component="span">{value}</Text>
                  </Text>
                );
              } else {
                formattedSections.push(
                  <Text key={`line-${index}-${lineIndex}`} mb={12}>
                    {line}
                  </Text>
                );
              }
            });
          }
        });
        
        return formattedSections;
      }
      
      // If no sections, format as regular text
      return response.split('\n')
        .filter(p => {
          const trimmed = p.trim();
          return trimmed !== '' && !trimmed.match(/\d+%/);
        })
        .map((paragraph, index) => (
          <Text key={`para-${index}`} mb={12}>
            {paragraph}
          </Text>
        ));
    } catch (error) {
      console.error('Error formatting horoscope text:', error);
      return <Text color="red">Error formatting horoscope text</Text>;
    }
  };

  return (
    <Box position="relative">
      <LoadingOverlay visible={loading || loadingHoroscopes} overlayBlur={2} />
      {subscriptionStatus === 'success' && (
        <Alert icon={<IconCheck size={16} />} title="Subscription Successful!" color="green" mb="md">
          Thank you for subscribing to our service.
        </Alert>
      )}
      {subscriptionStatus === 'canceled' && (
        <Alert icon={<IconAlertCircle size={16} />} title="Subscription Canceled" color="red" mb="md">
          Your subscription process was canceled.
        </Alert>
      )}

      <Title order={2}>User Settings</Title>
      <TextInput
        label="Username"
        placeholder="Your Username"
        required
        value={formData.userName}
        onChange={handleChange('userName')}
      />
      <TextInput
        label="Email"
        placeholder="Your email"
        required
        value={formData.email}
        onChange={handleChange('email')}
      />
      <Coordinates
        initialLongitude={formData.longitude}
        initialLatitude={formData.latitude}
        onCoordinatesChange={handleCoordinatesChange}
      />
      <DateTimePicker
        label="Birth Date and Time"
        placeholder="Pick date and time"
        value={formData.birthDatetime}
        onChange={handleDateTimeChange}
        clearable
      />
      <PasswordInput
        label="New Password"
        placeholder="New password"
        value={formData.password}
        onChange={handleChange('password')}
      />
      <PasswordInput
        label="Confirm Password"
        placeholder="Confirm new password"
        value={formData.confirmPassword}
        onChange={handleChange('confirmPassword')}
      />
      <Group position="apart" mt="xl">
        <Button color="red" onClick={onLogout}>Logout</Button>
        <Group>
          {user?.id === "17" && (
            <>
              <Button 
                color="violet" 
                onClick={handleGenerateData}
                loading={loading}
              >
                Generate Data
              </Button>
              <Button color="teal" onClick={() => {
                console.log('Generate Avatar clicked');
              }}>
                Generate Avatar
              </Button>
            </>
          )}
          <Button onClick={handleUpdate}>Update</Button>
        </Group>
      </Group>

      <Title order={2} mt="xl">Subscription Status</Title>
      <Box p="md" style={{ border: '1px solid #ddd', borderRadius: '4px' }}>
        <Text>Current Status: {subscriptionStatus}</Text>
        <Text size="sm" color="dimmed" mt="xs">
          {subscriptionStatus === 'none' 
            ? 'No active subscription'
            : `You have an active ${subscriptionStatus} subscription`}
        </Text>
        <Button 
          onClick={handleSubscribe} 
          disabled={subscriptionStatus === 'active'}
          mt="md"
        >
          {subscriptionStatus === 'active' ? 'Already Subscribed' : 'Subscribe Now'}
        </Button>
      </Box>

      <Paper shadow="md" p="xl" mt="xl" radius="md">
        <Title order={2} mb="md">Horoscope Calendar</Title>
        <Text color="dimmed" mb="lg">View available horoscopes by date</Text>
        
        <Paper shadow="sm" p="md" style={{ 
          backgroundColor: 'white',
          borderRadius: '8px',
          width: '100%'
        }}>
          <DatePicker
            value={selectedDate}
            onChange={handleDateChange}
            getDayProps={getDayProps}
            onMonthChange={fetchPopulatedDates}
            size="xl"
            styles={{
              calendarBase: {
                width: '100%'
              },
              calendarHeader: {
                backgroundColor: 'transparent',
                padding: '10px',
                fontSize: '18px'
              },
              monthCell: {
                fontSize: '18px'
              },
              weekday: {
                fontSize: '14px',
                padding: '10px',
                color: '#666',
                textAlign: 'center',
                width: 'calc(100% / 7)'
              },
              weekdaysRow: {
                display: 'flex',
                width: '100%'
              },
              month: {
                width: '100%'
              },
              monthRow: {
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between'
              },
              monthCell: {
                width: 'calc(100% / 7)',
                aspectRatio: '1'
              },
              day: {
                width: '100%',
                height: '100%',
                fontSize: '16px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '4px',
                margin: '2px',
                '&[data-selected]': {
                  backgroundColor: '#7950f2',
                  '&:hover': {
                    backgroundColor: '#6741d9'
                  }
                }
              }
            }}
          />
        </Paper>
        
        <Text size="sm" color="dimmed" mt="lg" style={{ display: 'flex', alignItems: 'center' }}>
          <Box component="span" 
            style={{ 
              width: '8px', 
              height: '8px', 
              borderRadius: '50%', 
              backgroundColor: '#7950f2', 
              display: 'inline-block',
              marginRight: '8px'
            }} 
          />
          Dates with available horoscopes
        </Text>
      </Paper>

      {user?.id === "17" && (
        <Paper shadow="xs" p="md" mt="xl">
          <Group position="apart" mb="md">
            <Title order={2}>Generated Horoscopes</Title>
            <Group spacing="xs">
              <Popover 
                opened={datePickerOpened} 
                onClose={() => setDatePickerOpened(false)}
                position="bottom"
                width={300}
                shadow="md"
              >
                <Popover.Target>
                  <TextInput
                    value={formatDateTime(selectedDate)}
                    onClick={() => setDatePickerOpened(true)}
                    readOnly
                    rightSection={
                      <Box style={{ cursor: 'pointer', color: '#666666' }}>
                        <IconCalendar size={16} />
                      </Box>
                    }
                    style={{ cursor: 'pointer', width: '200px' }}
                  />
                </Popover.Target>
                <Popover.Dropdown>
                  <DatePicker
                    value={selectedDate}
                    onChange={(date) => {
                      handleDateChange(date);
                      setDatePickerOpened(false);
                    }}
                    size="sm"
                  />
                </Popover.Dropdown>
              </Popover>
              <Button 
                color="violet" 
                onClick={handleGenerateData}
                loading={loading}
                leftIcon={<IconRefresh size={16} />}
              >
                Generate
              </Button>
            </Group>
          </Group>
          {loadingHoroscopes ? (
            <Text color="dimmed" align="center" mt="md">Loading horoscopes...</Text>
          ) : Object.keys(generatedHoroscopes).length > 0 ? (
            <Tabs value={activeHoroscopeType} onChange={setActiveHoroscopeType}>
              <Tabs.List>
                {Object.keys(generatedHoroscopes).map(type => (
                  <Tabs.Tab key={type} value={type}>
                    {formatHoroscopeType(type)}
                  </Tabs.Tab>
                ))}
              </Tabs.List>

              {Object.entries(generatedHoroscopes).map(([type, data]) => (
                <Tabs.Panel key={type} value={type} pt="xs">
                  <Paper p="xs" mb="xs" withBorder style={{ position: 'relative', width: '95%' }}>
                    <Group position="right" mb="md">
                      <Button 
                        variant="subtle" 
                        color="red" 
                        size="sm"
                        leftIcon={<IconTrash size={16} />}
                        onClick={() => handleRemoveHoroscope(type, data)}
                      >
                        Remove
                      </Button>
                    </Group>
                    <Box>
                      <Text mb="md"><strong>Type:</strong> {formatHoroscopeType(type)}</Text>
                      <Text mb="lg"><strong>Date:</strong> {formatDateTime(data.input_date)}</Text>
                      
                      {data.chatbot_response && (
                        <>
                          <Title order={4} mt="md" mb="md">Horoscope Interpretation</Title>
                          {formatChatbotResponse(data.chatbot_response)}

                          <Box mt="lg" h={300} style={{ maxWidth: '600px', margin: '0 auto' }}>
                            <ResponsiveContainer width="100%" height="100%">
                              <BarChart
                                data={extractPercentages(data.chatbot_response)}
                                margin={{ top: 20, right: 30, left: 20, bottom: 60 }}
                                barGap={8}
                                barSize={100}
                              >
                                <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
                                <XAxis
                                  dataKey="attribute"
                                  angle={0}
                                  textAnchor="middle"
                                  height={60}
                                  interval={0}
                                  tick={{ fill: '#666666', fontSize: 12 }}
                                />
                                <YAxis 
                                  domain={[0, 100]} 
                                  tick={{ fill: '#666666', fontSize: 12 }}
                                />
                                <RechartsTooltip 
                                  cursor={{ fill: 'transparent' }}
                                  contentStyle={{
                                    backgroundColor: 'white',
                                    border: '1px solid #f0f0f0',
                                    borderRadius: '4px',
                                    padding: '8px'
                                  }}
                                />
                                <Bar 
                                  dataKey="value" 
                                  radius={[4, 4, 0, 0]}
                                >
                                  {extractPercentages(data.chatbot_response).map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={barColors[index]} />
                                  ))}
                                </Bar>
                              </BarChart>
                            </ResponsiveContainer>
                          </Box>
                        </>
                      )}
                    </Box>
                  </Paper>
                </Tabs.Panel>
              ))}
            </Tabs>
          ) : (
            <Text color="dimmed" align="center" mt="md">
              No horoscopes found for {formatDateTime(selectedDate)}. Use the Generate Data button to create new horoscopes.
            </Text>
          )}
        </Paper>
      )}
    </Box>
  );
}

export default UserSettings;