import React, { useState, useEffect, useRef } from 'react';
import { Tabs, Menu } from '@mantine/core';
import { IconStar, IconLogin, IconSettings } from '@tabler/icons-react';
import { showNotification, updateNotification } from '@mantine/notifications';
import axios from '../axiosConfig';
import AuthenticationModal from './AuthenticationModal';
import UserSettings from './UserSettings';
import NewSearch from './NewSearch';
import YourChart from './YourChart';
import SavedCharts from './SavedCharts';
import CollectiveHoroscopes from './CollectiveHoroscopes';

const COLLECTIVE_TYPES = [
  { value: 'collective_daily', label: 'Daily Collective' },
  { value: 'collective_weekly', label: 'Weekly Collective' },
  { value: 'collective_monthly', label: 'Monthly Collective' },
  { value: 'politics', label: 'Political Forecast' },
  { value: 'warnings', label: 'Astrological Warnings' },
  { value: 'opportunities', label: 'Opportunity Forecast' },
  { value: 'travel', label: 'Travel Forecast' }
];

const getDateForHoroscopeType = (type) => {
  // Create a new date in UTC
  const now = new Date();
  const utcNow = new Date(Date.UTC(
    now.getUTCFullYear(),
    now.getUTCMonth(),
    now.getUTCDate(),
    now.getUTCHours(),
    now.getUTCMinutes(),
    now.getUTCSeconds()
  ));
  
  switch (type) {
    case 'collective_weekly':
      // Set to Monday of current week using UTC
      const monday = new Date(Date.UTC(
        utcNow.getUTCFullYear(),
        utcNow.getUTCMonth(),
        utcNow.getUTCDate()
      ));
      const dayOfWeek = monday.getUTCDay();
      monday.setUTCDate(monday.getUTCDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1));
      return monday;
      
    case 'collective_monthly':
      // Set to first day of current month using UTC
      return new Date(Date.UTC(
        utcNow.getUTCFullYear(),
        utcNow.getUTCMonth(),
        1
      ));
      
    default:
      // For daily and other types, use current date in UTC
      return new Date(Date.UTC(
        utcNow.getUTCFullYear(),
        utcNow.getUTCMonth(),
        utcNow.getUTCDate()
      ));
  }
};

function LandingTabs({ user, onLogin, onLogout, subscriptionStatus }) {
  const [modalOpened, setModalOpened] = useState(false);
  const [activeTab, setActiveTab] = useState('collective');
  const [pendingAction, setPendingAction] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [generatingHoroscope, setGeneratingHoroscope] = useState(false);
  
  // Create separate refs for each horoscope type
  const horoscopeRefs = {
    collective_daily: useRef(),
    collective_weekly: useRef(),
    collective_monthly: useRef(),
    politics: useRef(),
    warnings: useRef(),
    opportunities: useRef(),
    travel: useRef()
  };

  const generateHoroscope = async (type) => {
    const notificationId = `generating-${type}`;
    try {
      showNotification({
        id: notificationId,
        title: 'Generating',
        message: `Generating ${type} horoscope...`,
        loading: true,
        autoClose: false
      });

      const targetDate = getDateForHoroscopeType(type);
      console.log('Generating horoscope for date:', targetDate);

      const response = await axios.post('/api/collective-horoscopes/generate', {
        type: type,
        date: targetDate.toISOString()
      });

      // Remove the loading notification without showing a success message
      updateNotification({
        id: notificationId,
        title: '',
        message: '',
        autoClose: 1
      });

      // Directly update the CollectiveHoroscopes component with the new data
      if (horoscopeRefs[type].current?.updateHoroscope) {
        horoscopeRefs[type].current.updateHoroscope(response.data);
      } else {
        // Fallback to fetch if updateHoroscope is not available
        horoscopeRefs[type].current?.fetchHoroscopes?.();
      }

      return response.data;
    } catch (error) {
      console.error(`Error generating ${type} horoscope:`, error);
      updateNotification({
        id: notificationId,
        title: 'Error',
        message: `Failed to generate ${type} horoscope`,
        color: 'red',
        autoClose: 2000
      });
      throw error;
    }
  };

  const handleTabChange = async (value) => {
    console.log('Tab change:', { value, hasUser: !!user, currentTab: activeTab });
    
    if (value === 'login') {
      if (!user) {
        setModalOpened(true);
      } else {
        setActiveTab('userSettings');
      }
    } else {
      setActiveTab(value);
      setModalOpened(false);

      // Generate horoscope if it's a collective type
      const collectiveType = COLLECTIVE_TYPES.find(type => type.value === value);
      if (collectiveType && !generatingHoroscope) {
        setGeneratingHoroscope(true);
        try {
          await generateHoroscope(value);
          // Force a refresh after generation
          setTimeout(() => {
            if (horoscopeRefs[value].current?.fetchHoroscopes) {
              horoscopeRefs[value].current.fetchHoroscopes();
            }
          }, 500); // Small delay to ensure the component is ready
        } finally {
          setGeneratingHoroscope(false);
        }
      }
    }
    setPendingAction(null);
  };

  const handleMenuItemClick = async (action) => {
    console.log('Menu click:', { action, hasUser: !!user });
    
    if (user) {
      setActiveTab(action);
    } else if (action.startsWith('collective_') || ['politics', 'warnings', 'opportunities', 'travel'].includes(action)) {
      // For collective horoscopes, generate and switch tab even without login
      setActiveTab(action);
      if (!generatingHoroscope) {
        setGeneratingHoroscope(true);
        try {
          const horoscope = await generateHoroscope(action);
          // Force a refresh after generation using the correct ref
          setTimeout(() => {
            const ref = horoscopeRefs[action];
            if (ref?.current?.fetchHoroscopes) {
              ref.current.fetchHoroscopes();
            }
          }, 500);
        } finally {
          setGeneratingHoroscope(false);
        }
      }
    } else {
      setPendingAction(action);
      setModalOpened(true);
    }
  };

  const handleLogin = async (userData) => {
    console.log('Login attempt...', { 
      pendingAction, 
      email: userData.email 
    });

    try {
      await onLogin(userData);
      console.log('Login successful');
      
      if (pendingAction) {
        setActiveTab(pendingAction);
      } else {
        setActiveTab('userSettings');
      }
      setPendingAction(null);
      setModalOpened(false);
    } catch (error) {
      console.error('Login error:', error);
    }
  };

  useEffect(() => {
    return () => {
      // Cleanup when component unmounts
      setMenuOpen(false);
      setModalOpened(false);
    };
  }, []);

  return (
    <Tabs value={activeTab} onTabChange={handleTabChange}>
      <Tabs.List grow>
        <Menu 
          trigger="click"
          closeOnItemClick={true}
          withinPortal
          opened={menuOpen}
          onChange={setMenuOpen}
          zIndex={1000}
          style={{ flex: '1 0 40%' }}
        >
          <Menu.Target>
            <Tabs.Tab value="collective" leftSection={<IconStar size={14} />} style={{ width: '100%' }}>
              Collective
            </Tabs.Tab>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Label>General Horoscopes</Menu.Label>
            <Menu.Item onClick={() => handleMenuItemClick('collective_daily')}>Daily Collective</Menu.Item>
            <Menu.Item onClick={() => handleMenuItemClick('collective_weekly')}>Weekly Collective</Menu.Item>
            <Menu.Item onClick={() => handleMenuItemClick('collective_monthly')}>Monthly Collective</Menu.Item>
            <Menu.Divider />
            <Menu.Label>Special Forecasts</Menu.Label>
            <Menu.Item onClick={() => handleMenuItemClick('politics')}>Political Forecast</Menu.Item>
            <Menu.Item onClick={() => handleMenuItemClick('opportunities')}>Opportunity Forecast</Menu.Item>
            <Menu.Item onClick={() => handleMenuItemClick('warnings')}>Astrological Warnings</Menu.Item>
            <Menu.Item onClick={() => handleMenuItemClick('travel')}>Travel Forecast</Menu.Item>
          </Menu.Dropdown>
        </Menu>

        <Menu 
          trigger="click"
          closeOnItemClick={true}
          withinPortal
          zIndex={1000}
          style={{ flex: '1 0 40%' }}
        >
          <Menu.Target>
            <Tabs.Tab value="personal" leftSection={<IconStar size={14} />} style={{ width: '100%' }}>
              Personal
            </Tabs.Tab>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Label>Personal Horoscopes</Menu.Label>
            {/* <Menu.Item onClick={() => handleMenuItemClick('newSearch')}>
              New Search {!user && '(Login required)'}
            </Menu.Item>
            <Menu.Item onClick={() => handleMenuItemClick('yourChart')}>
              Your Chart {!user && '(Login required)'}
            </Menu.Item> */}
            <Menu.Item onClick={() => handleMenuItemClick('savedCharts')}>
              Your Saved Charts {!user && '(Login required)'}
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>

        <Tabs.Tab 
          value="login" 
          leftSection={user ? <IconSettings size={14} /> : <IconLogin size={14} />}
          style={{ flex: '1 0 20%' }}
          onClick={() => handleTabChange('login')}
        >
          {user ? 'User Settings' : 'Login'}
        </Tabs.Tab>
      </Tabs.List>

      <Tabs.Panel value="collective_daily" pt="xs">
        <CollectiveHoroscopes 
          ref={horoscopeRefs.collective_daily}
          defaultType="collective_daily" 
        />
      </Tabs.Panel>

      <Tabs.Panel value="collective_weekly" pt="xs">
        <CollectiveHoroscopes 
          ref={horoscopeRefs.collective_weekly}
          defaultType="collective_weekly" 
        />
      </Tabs.Panel>

      <Tabs.Panel value="collective_monthly" pt="xs">
        <CollectiveHoroscopes 
          ref={horoscopeRefs.collective_monthly}
          defaultType="collective_monthly" 
        />
      </Tabs.Panel>

      <Tabs.Panel value="politics" pt="xs">
        <CollectiveHoroscopes 
          ref={horoscopeRefs.politics}
          defaultType="politics" 
        />
      </Tabs.Panel>

      <Tabs.Panel value="opportunities" pt="xs">
        <CollectiveHoroscopes 
          ref={horoscopeRefs.opportunities}
          defaultType="opportunities" 
        />
      </Tabs.Panel>

      <Tabs.Panel value="warnings" pt="xs">
        <CollectiveHoroscopes 
          ref={horoscopeRefs.warnings}
          defaultType="warnings" 
        />
      </Tabs.Panel>

      <Tabs.Panel value="travel" pt="xs">
        <CollectiveHoroscopes 
          ref={horoscopeRefs.travel}
          defaultType="travel" 
        />
      </Tabs.Panel>

      {/* <Tabs.Panel value="newSearch" pt="xs">
        <NewSearch user={user} />
      </Tabs.Panel> */}

      {/* <Tabs.Panel value="yourChart" pt="xs">
        <YourChart user={user} />
      </Tabs.Panel> */}

      <Tabs.Panel value="savedCharts" pt="xs">
        <SavedCharts user={user} />
      </Tabs.Panel>

      <Tabs.Panel value="userSettings" pt="xs">
        {user ? (
          <UserSettings user={user} onLogout={onLogout} subscriptionStatus={subscriptionStatus} />
        ) : (
          <div>Please log in to access user settings.</div>
        )}
      </Tabs.Panel>

      <AuthenticationModal 
        opened={modalOpened} 
        onClose={() => {
          setModalOpened(false);
          setPendingAction(null);
          setMenuOpen(false);
        }} 
        onLogin={handleLogin}
        zIndex={1100}
      />
    </Tabs>
  );
}

export default LandingTabs;