// src/components/SubscriptionSuccess.js
import React from 'react';
import { Box, Title, Text, Button } from '@mantine/core';

function SubscriptionSuccess({ onClose }) {
  return (
    <Box>
      <Title order={1}>Subscription Successful!</Title>
      <Text>Thank you for subscribing to our service.</Text>
      <Button onClick={onClose}>
        Return to User Settings
      </Button>
    </Box>
  );
}

export default SubscriptionSuccess;