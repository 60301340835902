import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import { Box, Title, Paper, Text, Group, Modal } from '@mantine/core';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Cell } from 'recharts';
import axios from '../axiosConfig';
import { formatDateTime } from '../utils/dateUtils';
import collectiveMonthVideo from '../assets/video/collectiveMonth.mp4';
import collectiveWeekVideo from '../assets/video/collectiveWeekly.mp4';

const CollectiveHoroscopes = forwardRef(({ defaultType = 'collective_daily' }, ref) => {
  const [horoscopes, setHoroscopes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [lastFetchDate, setLastFetchDate] = useState(new Date());
  const currentType = useRef(defaultType);
  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);

  const barColors = ['#9694ff', '#82ca9d', '#ffd494'];

  // Helper function to generate new horoscope
  const generateNewHoroscope = async (date) => {
    try {
      await axios.post(`/api/collective-horoscopes/generate`, {
        type: defaultType,
        date: date.toISOString()
      });
      
      // Add a small delay to ensure the horoscope is saved
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      // Fetch the newly generated horoscope
      const newResponse = await axios.get(`/api/collective-horoscopes?type=${defaultType}`);
      setHoroscopes(newResponse.data);
    } catch (error) {
      console.error('Error generating horoscope:', error);
      setHoroscopes([]);
    }
  };

  // Keep the fetchLatestHoroscope function for internal use
  const fetchLatestHoroscope = async () => {
    try {
      setLoading(true);
      console.log('Fetching latest horoscope for type:', defaultType);
      const response = await axios.get(`/api/collective-horoscopes?type=${defaultType}`);
      console.log('Fetched horoscopes response:', response.data);
      
      if (response.data && response.data.length > 0) {
        console.log('Setting horoscopes with data:', response.data);
        setHoroscopes(response.data);
      } else {
        console.log('No horoscopes found, generating new one');
        await generateNewHoroscope(new Date());
      }
      setLastFetchDate(new Date());
    } catch (error) {
      if (error.response?.status !== 401) {
        console.error('Error fetching collective horoscopes:', error);
      }
      setHoroscopes([]);
    } finally {
      setLoading(false);
    }
  };

  // Initial fetch of horoscopes
  useEffect(() => {
    fetchLatestHoroscope();
  }, [defaultType]);

  // Function to get the display title based on type
  const getDisplayTitle = (type) => {
    switch (type) {
      case 'collective_daily':
        return 'Daily Collective Horoscope';
      case 'collective_weekly':
        return 'Weekly Collective Horoscope';
      case 'collective_monthly':
        return 'Monthly Collective Horoscope';
      case 'politics':
        return 'Political Forecast';
      case 'warnings':
        return 'Astrological Warnings';
      case 'opportunities':
        return 'Opportunity Forecast';
      case 'travel':
        return 'Travel Forecast';
      default:
        return 'Collective Horoscope';
    }
  };

  // Check if we need to refresh based on horoscope type
  const needsRefresh = (type, lastFetch) => {
    if (!lastFetch) return true;
    
    const now = new Date();
    const lastFetchDate = new Date(lastFetch);
    
    // Check if the horoscope is from a previous day
    const isOldDate = lastFetchDate.getDate() !== now.getDate() ||
                     lastFetchDate.getMonth() !== now.getMonth() ||
                     lastFetchDate.getFullYear() !== now.getFullYear();
    
    // Always refresh if it's an old date or if more than 10 minutes have passed
    if (isOldDate || (now - lastFetchDate) >= (10 * 60 * 1000)) {
      return true;
    }
    
    // Additional type-specific checks
    switch (type) {
      case 'collective_weekly': {
        const getWeekNumber = (d) => {
          const firstDayOfYear = new Date(d.getFullYear(), 0, 1);
          const pastDaysOfYear = (d - firstDayOfYear) / 86400000;
          return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
        };
        return getWeekNumber(lastFetchDate) !== getWeekNumber(now) ||
               lastFetchDate.getFullYear() !== now.getFullYear();
      }
        
      case 'collective_monthly':
        return lastFetchDate.getMonth() !== now.getMonth() ||
               lastFetchDate.getFullYear() !== now.getFullYear();
        
      default:
        // For all types (including politics, warnings, opportunities, travel)
        // Return true if the date is not today
        return isOldDate;
    }
  };

  // Update currentType when defaultType changes
  useEffect(() => {
    if (currentType.current !== defaultType) {
      currentType.current = defaultType;
      // Fetch existing horoscopes when type changes
      fetchLatestHoroscope();
    }
  }, [defaultType]);

  const formatHoroscopeText = (text) => {
    if (!text) return null;

    try {
      // Split into sections if present
      const sections = text.split(/\[.*?\]/).filter(Boolean);
      const headers = text.match(/\[.*?\]/g) || [];
      
      if (sections.length > 1) {
        let formattedSections = [];
        
        // Format each section with its header
        sections.forEach((section, index) => {
          const header = headers[index];
          const content = section.trim();
          
          if (header) {
            // Remove square brackets and format as Title
            const cleanHeader = header.replace(/[\[\]]/g, '');
            formattedSections.push(
              <Title key={`header-${index}`} order={4} mt={24} mb={16} color="indigo">
                {cleanHeader}
              </Title>
            );
          }
          
          // Handle fundamentals sections with special formatting
          if (content.includes('Elements:') || content.includes('Modalities:') || content.includes('Energies:')) {
            const parts = content.split(/(?=Elements:|Modalities:|Energies:)/);
            parts.forEach((part, partIndex) => {
              if (part.trim()) {
                const [subHeader, ...details] = part.split(':');
                formattedSections.push(
                  <Text key={`fundamental-${index}-${partIndex}`} mb={12}>
                    <Text component="span" weight={700} color="indigo">{subHeader}:</Text>
                    <Text component="span">{details.join(':')}</Text>
                  </Text>
                );
              }
            });
          } else {
            // Handle regular content and aspects
            const lines = content.split('\n').filter(line => line.trim());
            lines.forEach((line, lineIndex) => {
              if (line.includes(':')) {
                const [key, ...valueParts] = line.split(':');
                const value = valueParts.join(':').trim();
                formattedSections.push(
                  <Text key={`line-${index}-${lineIndex}`} mb={12}>
                    <Text component="span" weight={700} color="indigo">{key}:</Text>
                    <Text component="span">{value}</Text>
                  </Text>
                );
              } else {
                formattedSections.push(
                  <Text key={`line-${index}-${lineIndex}`} mb={12}>
                    {line}
                  </Text>
                );
              }
            });
          }
        });
        
        return formattedSections;
      }
      
      // If no sections, format as regular text
      return text.split('\n')
        .filter(p => {
          const trimmed = p.trim();
          return trimmed !== '' && !trimmed.match(/\d+%/);
        })
        .map((paragraph, index) => (
          <Text key={`para-${index}`} mb={12}>
            {paragraph}
          </Text>
        ));
    } catch (error) {
      console.error('Error formatting horoscope text:', error);
      return <Text color="red">Error formatting horoscope text</Text>;
    }
  };

  const extractPercentages = (chatbotResponse) => {
    if (!chatbotResponse) return [];
    
    // Default attributes we're looking for - removed Charisma for collective horoscopes
    const attributes = ['Intuition', 'Energy', 'Luck'];
    
    // Map to handle both English and Swedish terms
    const attributeMap = {
      'Intuition': 'Intuition',
      'Energy': 'Energi',
      'Luck': 'Tur'
    };
    
    // First try to find the numerical values section
    const numericalSection = chatbotResponse.split('Numerical Values:')[1];
    if (numericalSection) {
      return attributes.map(attribute => {
        const pattern = new RegExp(`${attribute}:\\s*(\\d+)%`);
        const match = numericalSection.match(pattern);
        return {
          attribute,
          value: match ? parseInt(match[1], 10) : 0
        };
      });
    }
    
    // Fallback to searching in the entire text
    return attributes.map(attribute => {
      // Try different patterns for both English and Swedish terms
      const patterns = [
        // Match the exact format: "- Attribute: number"
        new RegExp(`-\\s*${attribute}:\\s*(\\d+)`),
        // Also try without the dash in case it's missing
        new RegExp(`${attribute}:\\s*(\\d+)`),
        // Try Swedish translations with same patterns
        new RegExp(`-\\s*${attributeMap[attribute]}:\\s*(\\d+)`),
        new RegExp(`${attributeMap[attribute]}:\\s*(\\d+)`)
      ];
      
      for (const pattern of patterns) {
        const match = chatbotResponse.match(pattern);
      if (match) {
          return {
            attribute,
            value: parseInt(match[1], 10)
          };
        }
      }
      
      return {
        attribute,
        value: 0
      };
    });
  };

  const formatDateRange = (type, date) => {
    const dateObj = new Date(date);
    const formattedDate = dateObj.toLocaleDateString('en-US', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
    
    switch (type) {
      case 'collective_weekly': {
        // Get Monday of the week
        const weekStart = new Date(dateObj);
        weekStart.setDate(dateObj.getDate() - dateObj.getDay() + (dateObj.getDay() === 0 ? -6 : 1));
        weekStart.setHours(0, 0, 0, 0);
        
        // Get Sunday of the week
        const weekEnd = new Date(weekStart);
        weekEnd.setDate(weekStart.getDate() + 6);
        
        return {
          title: `${getDisplayTitle(type)} for week of ${weekStart.toLocaleDateString()} - ${weekEnd.toLocaleDateString()}`,
          date: null
        };
      }
      
      case 'collective_monthly': {
        const monthStart = new Date(dateObj.getFullYear(), dateObj.getMonth(), 1);
        
        return {
          title: `${getDisplayTitle(type)} for ${monthStart.toLocaleString('default', { month: 'long', year: 'numeric' })}`,
          date: null
        };
      }
      
      case 'politics':
      case 'warnings':
      case 'opportunities':
      case 'travel': {
        return {
          title: `${getDisplayTitle(type)} for ${formattedDate}`,
          date: null
        };
      }
      
      default: {
        return {
          title: `${getDisplayTitle(type)} for ${formattedDate}`,
          date: null
        };
      }
    }
  };

  // Reset audio when type changes
  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current = null;
      setIsPlaying(false);
    }
  }, [defaultType]);

  const handleVideoClick = () => {
    // Reset audio when opening video
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current = null;
      setIsPlaying(false);
    }
    setVideoModalOpen(true);
  };

  const handleAudioClick = (e) => {
    e.stopPropagation();
    const videoSource = defaultType === 'collective_monthly' ? collectiveMonthVideo : collectiveWeekVideo;
    
    if (!audioRef.current) {
      audioRef.current = new Audio(videoSource);
      audioRef.current.addEventListener('ended', () => {
        setIsPlaying(false);
      });
    }
    
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  // Expose methods through ref
  useImperativeHandle(ref, () => ({
    fetchHoroscopes: fetchLatestHoroscope,
    updateHoroscope: (newHoroscope) => {
      console.log('Updating horoscope directly:', newHoroscope);
      setHoroscopes([newHoroscope]);
      setLastFetchDate(new Date());
    }
  }));

  useEffect(() => {
    // Cleanup audio on unmount
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current = null;
      }
    };
  }, []);

  return (
    <Box p="0">
      {loading ? (
        <Text>Loading horoscope...</Text>
      ) : horoscopes.length === 0 ? (
        <Text>Loading your horoscope...</Text>
      ) : (
        horoscopes.map(horoscope => {
          console.log('Rendering horoscope:', horoscope);
          const dateFormat = formatDateRange(defaultType, horoscope.input_date);
          return (
            <Paper key={horoscope.id} p="xs" mb="xs" withBorder style={{ position: 'relative', width: '95%' }}>
              {(defaultType === 'collective_monthly' || defaultType === 'collective_weekly') && (
                <Paper style={{ 
                  display: 'flex', 
                  justifyContent: 'flex-start', 
                  alignItems: 'flex-start', 
                  marginBottom: 6, 
                  padding: '0 16px 0 0'
                }}>
                  <Box 
                    style={{ 
                      width: '100%',
                      background: 'rgba(0,0,0,0.05)',
                      borderRadius: '12px',
                      padding: '6px 6px 6px 6px',
                      display: 'flex',
                      gap: '5px',
                      boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
                      border: '1px solid rgba(0,0,0,0.15)',
                      flexShrink: 0,
                      marginTop: '6px',
                      marginLeft: '6px',
                      marginRight: '120px'
                    }}
                  >
                    <Box
                      onClick={handleVideoClick}
                      style={{ position: 'relative', width: '80px', flexShrink: 0 }}
                    >
                      <video
                        width="100%"
                        style={{
                          borderRadius: '10px',
                          boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                        }}
                      >
                        <source src={defaultType === 'collective_monthly' ? collectiveMonthVideo : collectiveWeekVideo} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                      <Box 
                        style={{ 
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                          background: 'rgba(0,0,0,0.5)',
                          color: 'white',
                          padding: '3px 6px',
                          borderRadius: '4px',
                          fontSize: '11px',
                          whiteSpace: 'normal',
                          width: '90%',
                          textAlign: 'center',
                          lineHeight: '1.2'
                        }}
                      >
                        Click to play
                      </Box>
                    </Box>
                    <Box style={{ display: 'flex', flexDirection: 'column', gap: '5px', flex: 1, marginRight: '20px' }}>
                      <Title order={2} style={{ fontSize: '20px', lineHeight: 1.4, margin: '0', fontWeight: 700 }}>
                        {dateFormat.title}
                      </Title>
                      <Box
                        style={{
                          cursor: 'pointer',
                          background: 'rgba(0,0,0,0.1)',
                          borderRadius: '10px',
                          padding: '10px',
                          display: 'flex',
                          alignItems: 'flex-start',
                          justifyContent: 'center',
                          width: '52px',
                          height: '52px',
                          flexShrink: 0
                        }}
                        onClick={handleAudioClick}
                      >
                        {isPlaying ? (
                          <svg width="35" height="35" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                            <rect x="6" y="4" width="4" height="16"/>
                            <rect x="14" y="4" width="4" height="16"/>
                          </svg>
                        ) : (
                          <svg width="35" height="35" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                            <path d="M11 5L6 9H2v6h4l5 4V5z"/>
                            <path d="M15.54 8.46a5 5 0 0 1 0 7.07"/>
                          </svg>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Paper>
              )}

              <Box>
                {!defaultType.includes('monthly') && !defaultType.includes('weekly') && (
                  <Title order={2} mb="md">
                    {dateFormat.title}
                  </Title>
                )}

                {horoscope.chatbot_response?.includes('aspects') || horoscope.chatbot_response?.includes('fundamentals') ? (
                  <Text mb="lg" italic>
                    The following astrological elements are sorted by significance, with the most influential aspects and fundamentals listed first.
                  </Text>
                ) : null}

                <Text size="lg" mb="lg" style={{ whiteSpace: 'pre-line' }}>
                  {formatHoroscopeText(horoscope.chatbot_response)}
                </Text>
              </Box>

              {(defaultType === 'collective_monthly' || defaultType === 'collective_weekly') && (
                <Modal
                  opened={videoModalOpen}
                  onClose={() => setVideoModalOpen(false)}
                  size="lg"
                  centered
                  padding={0}
                  withCloseButton={false}
                >
                  <video
                    controls
                    width="100%"
                    autoPlay
                    style={{
                      borderRadius: '4px'
                    }}
                  >
                    <source src={defaultType === 'collective_monthly' ? collectiveMonthVideo : collectiveWeekVideo} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </Modal>
              )}

              <Box h={300} style={{ maxWidth: '600px', margin: '0 auto' }}>
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    data={extractPercentages(horoscope.chatbot_response)}
                    margin={{ top: 20, right: 30, left: 20, bottom: 60 }}
                    barGap={8}
                    barSize={100}
                  >
                    <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
                    <XAxis
                      dataKey="attribute"
                      angle={0}
                      textAnchor="middle"
                      height={60}
                      interval={0}
                      tick={{ fill: '#666666', fontSize: 12 }}
                    />
                    <YAxis 
                      domain={[0, 100]} 
                      tick={{ fill: '#666666', fontSize: 12 }}
                    />
                    <Tooltip 
                      cursor={{ fill: 'transparent' }}
                      contentStyle={{
                        backgroundColor: 'white',
                        border: '1px solid #f0f0f0',
                        borderRadius: '4px',
                        padding: '8px'
                      }}
                    />
                    <Bar 
                      dataKey="value" 
                      radius={[4, 4, 0, 0]}
                    >
                      {extractPercentages(horoscope.chatbot_response).map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={barColors[index]} />
                      ))}
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </Box>
            </Paper>
          );
        })
      )}
    </Box>
  );
});

export default CollectiveHoroscopes; 