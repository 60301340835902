// src/components/SavedCharts.js

import React, { useState, useEffect, useCallback } from 'react';
import { Box, Title, Button, Stack, Select, LoadingOverlay } from '@mantine/core';
import { DateTimePicker } from '@mantine/dates';
import axios from '../../src/axiosConfig';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import SavedHoroscopeDisplay from './SavedHoroscopeDisplay';
import Coordinates from './Coordinates';  // Import the Coordinates component

function SavedCharts({ user }) {
  const [horoscopeData, setHoroscopeData] = useState({
    horoscopeType: '',
    inputDate: new Date(),
    birthDate: null,
    longitude: '',
    latitude: '',
  });

  const [savedHoroscopes, setSavedHoroscopes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const checkAuth = useCallback(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsAuthenticated(true);
      return token;
    }
    setIsAuthenticated(false);
    return null;
  }, []);

  const fetchSavedHoroscopes = useCallback(async () => {
    try {
      const token = checkAuth();
      if (!token) {
        console.log('No token available');
        setLoading(false);
        return;
      }

      console.log('Fetching saved horoscopes...');
      
      const response = await axios.get('/api/saved-horoscopes', {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      });

      console.log('Response data type:', typeof response.data);
      console.log('Response data:', JSON.stringify(response.data, null, 2));
      
      if (response.status === 200) {
        if (!Array.isArray(response.data)) {
          console.error('Expected array of horoscopes but got:', typeof response.data);
          setSavedHoroscopes([]);
          return;
        }

        const sortedHoroscopes = [...response.data].sort((a, b) => b.id - a.id);
        console.log('Sorted horoscopes:', JSON.stringify(sortedHoroscopes, null, 2));
        setSavedHoroscopes(sortedHoroscopes);
      }
    } catch (error) {
      console.error('Fetch error details:', error);
      setSavedHoroscopes([]);
      showNotification({
        title: 'Error',
        message: error.response?.data?.error || error.message,
        color: 'red'
      });
    } finally {
      setLoading(false);
    }
  }, [checkAuth]);

  // Check auth when user prop changes
  useEffect(() => {
    if (user) {
      checkAuth();
    }
  }, [user, checkAuth]);

  // Fetch horoscopes when authentication state changes
  useEffect(() => {
    if (isAuthenticated) {
      fetchSavedHoroscopes();
    } else {
      setSavedHoroscopes([]);
    }
  }, [isAuthenticated, fetchSavedHoroscopes]);

  // Initial setup
  useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  const handleHoroscopeChange = (field) => (value) => {
    setHoroscopeData(prevData => ({ ...prevData, [field]: value }));
  };

  const handleCoordinatesChange = (latitude, longitude) => {
    setHoroscopeData(prevData => ({ ...prevData, latitude, longitude }));
  };

  const handleCreateHoroscope = async () => {
    if (!horoscopeData.horoscopeType || !horoscopeData.inputDate || !horoscopeData.birthDate || !horoscopeData.longitude || !horoscopeData.latitude) {
      showNotification({
        title: 'Error',
        message: 'Please fill in all fields',
        color: 'red',
        icon: <IconX size={18} />,
      });
      return;
    }

    setLoading(true);
    try {
      // Format dates as ISO strings
      const formattedData = {
        horoscope_type: horoscopeData.horoscopeType,
        input_date: horoscopeData.inputDate.toISOString(),
        birth_dates: [horoscopeData.birthDate.toISOString()],
        latitude: parseFloat(horoscopeData.latitude),
        longitude: parseFloat(horoscopeData.longitude),
        horoscope_data: {
          input_coordinates: {
            latitude: parseFloat(horoscopeData.latitude),
            longitude: parseFloat(horoscopeData.longitude)
          }
        }
      };

      console.log('Sending horoscope data:', formattedData);

      showNotification({
        title: 'Processing',
        message: 'Generating your horoscope... This may take a few moments.',
        color: 'blue',
        loading: true,
        autoClose: 3000,
        id: 'horoscope-loading'
      });

      const response = await axios.post('/api/saved-horoscopes/generate', formattedData);
      console.log('Server response:', response.data);

      // Close the loading notification immediately when done
      showNotification({
        id: 'horoscope-loading',
        title: 'Success',
        message: 'Your horoscope has been created and saved',
        color: 'teal',
        icon: <IconCheck size={18} />,
        autoClose: true
      });

      setHoroscopeData({
        horoscopeType: '',
        inputDate: new Date(),
        birthDate: null,
        longitude: '',
        latitude: '',
      });
      
      await fetchSavedHoroscopes();
    } catch (error) {
      console.error('Horoscope creation failed:', error);
      // Close the loading notification
      showNotification({
        id: 'horoscope-loading',
        autoClose: true
      });
      
      showNotification({
        title: 'Error',
        message: 'Failed to create horoscope: ' + (error.response?.data?.error || error.message),
        color: 'red',
        icon: <IconX size={18} />,
        autoClose: true
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteHoroscope = async (id) => {
    try {
      console.log('Attempting to delete horoscope:', id);
      
      await axios.delete(`/api/saved-horoscopes/${id}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      });
      
      setSavedHoroscopes(prev => prev.filter(h => h.id !== id));
      
      showNotification({
        title: 'Success',
        message: 'Horoscope deleted successfully',
        color: 'green'
      });
    } catch (error) {
      console.error('Delete error details:', {
        id,
        error: error.message,
        response: error.response?.data,
        status: error.response?.status
      });
      
      showNotification({
        title: 'Error',
        message: `Failed to delete horoscope: ${error.response?.data?.message || error.message}`,
        color: 'red'
      });
    }
  };

  if (loading) {
    return <div>Loading saved horoscopes...</div>;
  }

  if (!isAuthenticated) {
    return <div>Please log in to view your saved horoscopes.</div>;
  }

  return (
    <Box position="relative">
      <LoadingOverlay visible={loading} overlayBlur={2} />
      <Title order={2}>Create Horoscope</Title>
      <Box p="md" style={{ border: '1px solid #ddd', borderRadius: '4px' }}>
        <Select
          label="Horoscope Type"
          placeholder="Select horoscope type"
          data={[
            { value: 'natal', label: 'Natal Chart' },
            { value: 'transit', label: 'Transit Analysis' },
            { value: 'personal', label: 'Personal Daily' },
            { value: 'daily_eng', label: 'Daily (English)' },
            { value: 'daily', label: 'Daily (Swedish)' },
            { value: 'love', label: 'Love & Romance' },
            { value: 'friends', label: 'Friends & Social' },
            { value: 'personality', label: 'Personality' },
            { value: 'career', label: 'Career' },
            { value: 'collective_daily', label: 'Collective Daily' },
            { value: 'collective_weekly', label: 'Collective Weekly' },
            { value: 'collective_monthly', label: 'Collective Monthly' }
          ]}
          value={horoscopeData.horoscopeType}
          onChange={handleHoroscopeChange('horoscopeType')}
          withinPortal
          zIndex={1000}
          searchable
          nothingFound="No matching type"
        />
        <DateTimePicker
          label="Input Date and Time"
          placeholder="Pick date and time"
          value={horoscopeData.inputDate}
          onChange={handleHoroscopeChange('inputDate')}
          clearable
          withinPortal
          zIndex={1000}
        />
        <DateTimePicker
          label="Birth Date and Time"
          placeholder="Pick date and time"
          value={horoscopeData.birthDate}
          onChange={handleHoroscopeChange('birthDate')}
          clearable
          withinPortal
          zIndex={1000}
        />
        <Coordinates
          initialLongitude={horoscopeData.longitude}
          initialLatitude={horoscopeData.latitude}
          onCoordinatesChange={handleCoordinatesChange}
        />
        <Button onClick={handleCreateHoroscope} mt="md" fullWidth>Create Horoscope</Button>
      </Box>

      <SavedHoroscopeDisplay 
        savedHoroscopes={savedHoroscopes} 
        handleDeleteHoroscope={handleDeleteHoroscope} 
      />
    </Box>
  );
}

export default SavedCharts;