// frontend/src/components/Auth0Callback.js
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

function Auth0Callback() {
  const navigate = useNavigate();
  const location = useLocation();
  const [error, setError] = useState(null);

  useEffect(() => {
    const handleCallback = async () => {
      const searchParams = new URLSearchParams(location.search);
      const code = searchParams.get('code');

      if (!code) {
        setError('No code found in the URL');
        return;
      }

      try {
        // Exchange the code for a token
        const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/callback`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ code }),
        });

        if (!response.ok) {
          throw new Error('Failed to exchange code for token');
        }

        const data = await response.json();

        // Store the token
        localStorage.setItem('token', data.token);

        // Redirect to the main app
        navigate('/');
      } catch (error) {
        console.error('Error during Auth0 callback:', error);
        setError('Failed to complete authentication');
      }
    };

    handleCallback();
  }, [location, navigate]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  return <div>Completing authentication, please wait...</div>;
}

export default Auth0Callback;