import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom/client';
import { Box, Text, Button, Stack, Title, Group, Paper, Modal, TextInput, CloseButton } from '@mantine/core';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Cell } from 'recharts';
import { formatDateTime, formatHoroscopeType } from '../utils/dateUtils';
import axios from '../axiosConfig';
import { IconDownload, IconTrash, IconMail, IconShoppingCart, IconBrain } from '@tabler/icons-react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { showNotification } from '@mantine/notifications';
import ShopM from './ShopM';

const SavedHoroscopeDisplay = ({ savedHoroscopes, handleDeleteHoroscope }) => {
  const [page, setPage] = useState(1);
  const itemsPerPage = 10;
  const [localHoroscopes, setLocalHoroscopes] = useState(savedHoroscopes);
  const [currentShopHoroscope, setCurrentShopHoroscope] = useState(null);
  const spiralModelRef = useRef(null);

  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [emailInput, setEmailInput] = useState('');
  const [processingEmail, setProcessingEmail] = useState(false);
  const [currentHoroscope, setCurrentHoroscope] = useState(null);

  const token = localStorage.getItem('token');
  useEffect(() => {
    if (token) {
      fetchHoroscopes(token);
    }
  }, [token]);

  const fetchHoroscopes = async (token) => {
    try {
      const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:10000/api';
      console.log('Using API URL:', API_URL); // Debug log
      console.log('Fetching horoscopes with token:', token); // Debug log
      
      const response = await axios.get(`${API_URL}/saved-horoscopes`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      
      console.log('Response:', response.data); // Debug log
      if (Array.isArray(response.data)) {
        setLocalHoroscopes(response.data);
      }
    } catch (error) {
      console.error('Error fetching horoscopes:', error);
    }
  };

  // Reset page when horoscopes change
  useEffect(() => {
    setPage(1);
    setLocalHoroscopes(savedHoroscopes);
    
    // If we have a new horoscope, scroll to it
    if (savedHoroscopes.length > 0) {
      const latestHoroscope = savedHoroscopes[0];
      const element = document.getElementById(`horoscope-content-${latestHoroscope.id}`);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [savedHoroscopes]);

  const paginatedHoroscopes = localHoroscopes.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  console.log('SavedHoroscopeDisplay received horoscopes:', savedHoroscopes?.length);
  console.log('First few horoscopes:', savedHoroscopes?.slice(0, 3));

  const extractPercentages = (chatbotResponse, horoscopeType) => {
    if (!chatbotResponse) return [];
    
    // Debug log
    console.log('Extracting percentages for:', horoscopeType, chatbotResponse);
    
    // Default attributes for personal horoscopes
    const defaultAttributes = ['Intuition', 'Energy', 'Luck', 'Charisma'];
    
    // Special attributes for specific horoscope types
    const specialAttributes = {
      natal: ['Self-Expression', 'Life Purpose', 'Relationships', 'Growth Potential'],
      transit: ['Opportunity', 'Challenge', 'Timing', 'Growth'],
      personal: ['Vitality', 'Clarity', 'Emotional Balance', 'Manifesting Power'],
      // Public/collective horoscopes don't include Charisma
      collective_daily: ['Intuition', 'Energy', 'Luck'],
      collective_weekly: ['Intuition', 'Energy', 'Luck'],
      collective_monthly: ['Intuition', 'Energy', 'Luck'],
      politics: ['Intuition', 'Energy', 'Luck'],
      warnings: ['Intuition', 'Energy', 'Luck'],
      opportunities: ['Intuition', 'Energy', 'Luck'],
      travel: ['Intuition', 'Energy', 'Luck']
    };
    
    // Choose attributes based on horoscope type
    const attributes = specialAttributes[horoscopeType] || defaultAttributes;
    
    // Try to find values using different formats
    const values = attributes.map(attribute => {
      // First try to find values in the "Numerical Values:" section
      const numericalSection = chatbotResponse.split('Numerical Values:')[1];
      if (numericalSection) {
        const sectionMatch = numericalSection.match(new RegExp(`${attribute}:\\s*(\\d+)%`));
        if (sectionMatch) {
          return {
            attribute,
            value: parseInt(sectionMatch[1], 10)
          };
        }
      }

      // If not found in numerical section, try different patterns in the whole text
      const patterns = [
        new RegExp(`${attribute}:\\s*(\\d+)%`),  // Format: "Attribute: 80%"
        new RegExp(`${attribute}\\s*(\\d+)%`),    // Format: "Attribute 80%"
        new RegExp(`${attribute}:\\s*(\\d+)`),    // Format: "Attribute: 80"
        new RegExp(`${attribute}\\s*(\\d+)`)      // Format: "Attribute 80"
      ];
      
      for (const pattern of patterns) {
        const match = chatbotResponse.match(pattern);
        if (match) {
          return {
            attribute,
            value: parseInt(match[1], 10)
          };
        }
      }
      
      // If no match found, try Swedish translations for daily horoscope
      if (horoscopeType === 'daily') {
        const swedishMap = {
          'Intuition': 'Intuition',
          'Energy': 'Energi',
          'Luck': 'Tur',
          'Charisma': 'Karisma'
        };
        
        const swedishAttribute = swedishMap[attribute];
        if (swedishAttribute) {
          for (const pattern of patterns) {
            const match = chatbotResponse.match(pattern.source.replace(attribute, swedishAttribute));
            if (match) {
              return {
                attribute,
                value: parseInt(match[1], 10)
              };
            }
          }
        }
      }
      
      // Debug log for missing values
      console.log(`No value found for attribute: ${attribute}`);
      console.log('Response text:', chatbotResponse);
      
      return {
        attribute,
        value: 0
      };
    });
    
    // Debug log
    console.log('Extracted values:', values);
    
    return values;
  };

  const formatChatbotResponse = (response) => {
    if (!response) return null;
    
    try {
      // Split into sections if present
      const sections = response.split(/\[.*?\]/).filter(Boolean);
      const headers = response.match(/\[.*?\]/g) || [];
      
      if (sections.length > 1) {
        // Add note about weighted sorting if present
        let formattedSections = [];
        if (response.includes('aspects') || response.includes('fundamentals')) {
          formattedSections.push(
            <Text key="weighted-note" mb={16} italic>
              The following astrological elements are sorted by significance, with the most influential aspects and fundamentals listed first.
            </Text>
          );
        }
        
        // Format each section with its header
        sections.forEach((section, index) => {
          const header = headers[index];
          const content = section.trim();
          
          if (header) {
            // Remove square brackets and format as Title
            const cleanHeader = header.replace(/[\[\]]/g, '');
            formattedSections.push(
              <Title key={`header-${index}`} order={4} mt={24} mb={16} color="indigo">
                {cleanHeader}
              </Title>
            );
          }
          
          // Handle fundamentals sections with special formatting
          if (content.includes('Elements:') || content.includes('Modalities:') || content.includes('Energies:')) {
            const parts = content.split(/(?=Elements:|Modalities:|Energies:)/);
            parts.forEach((part, partIndex) => {
              if (part.trim()) {
                const [subHeader, ...details] = part.split(':');
                formattedSections.push(
                  <Text key={`fundamental-${index}-${partIndex}`} mb={12}>
                    <Text component="span" weight={700} color="indigo">{subHeader}:</Text>
                    <Text component="span">{details.join(':')}</Text>
                  </Text>
                );
              }
            });
          } else {
            // Handle regular content and aspects
            const lines = content.split('\n').filter(line => line.trim());
            lines.forEach((line, lineIndex) => {
              if (line.includes(':')) {
                const [key, ...valueParts] = line.split(':');
                const value = valueParts.join(':').trim();
                formattedSections.push(
                  <Text key={`line-${index}-${lineIndex}`} mb={12}>
                    <Text component="span" weight={700} color="indigo">{key}:</Text>
                    <Text component="span">{value}</Text>
                  </Text>
                );
              } else {
                formattedSections.push(
                  <Text key={`line-${index}-${lineIndex}`} mb={12}>
                    {line}
                  </Text>
                );
              }
            });
          }
        });
        
        return formattedSections;
      }
      
      // If no sections, format as regular text
      return response.split('\n')
        .filter(p => {
          const trimmed = p.trim();
          return trimmed !== '' && !trimmed.match(/\d+%/);
        })
        .map((paragraph, index) => (
          <Text key={`para-${index}`} mb={12}>
            {paragraph}
          </Text>
        ));
    } catch (error) {
      console.error('Error formatting chatbot response:', error);
      return <Text color="red">Error formatting response</Text>;
    }
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div style={{ backgroundColor: 'white', padding: '5px', border: '1px solid #ccc' }}>
          <p>{`${label}: ${payload[0].value}%`}</p>
        </div>
      );
    }
    return null;
  };

  const barColors = ['#8884d8', '#82ca9d', '#ffc658', '#ff8042'];

  const generatePDF = async (horoscope) => {
    try {
      // Create PDF document
      const pdf = new jsPDF({
        unit: 'mm',
        format: 'a4',
        compress: true
      });

      // Set up dimensions
      const pageWidth = pdf.internal.pageSize.getWidth();
      const margin = 20;
      const contentWidth = pageWidth - (2 * margin);
      let yPosition = 20;

      // Basic details
      pdf.setFontSize(12);
      pdf.setTextColor(0);
      pdf.text(`Type: ${formatHoroscopeType(horoscope.horoscope_type)}`, margin, yPosition);
      yPosition += 10;
      pdf.text(`Input Date: ${formatDateTime(horoscope.input_date)}`, margin, yPosition);
      yPosition += 10;
      pdf.text(`Latitude: ${horoscope.latitude}`, margin, yPosition);
      yPosition += 10;
      pdf.text(`Longitude: ${horoscope.longitude}`, margin, yPosition);
      yPosition += 20;

      // Interpretation header
      pdf.setFontSize(14);
      pdf.text('Horoscope Interpretation', margin, yPosition);
      yPosition += 15;

      // Interpretation text
      pdf.setFontSize(12);
      const interpretation = horoscope.chatbot_response
        .split('\n')
        .filter(line => !line.match(/\d+%/))
        .join('\n\n');

      const splitText = pdf.splitTextToSize(interpretation, contentWidth);
      let textLines = splitText;
      const linesPerPage = Math.floor((pdf.internal.pageSize.height - yPosition - margin) / 5);

      while (textLines.length > 0) {
        if (yPosition > pdf.internal.pageSize.height - margin) {
          pdf.addPage();
          yPosition = margin;
        }
        
        const currentPageLines = textLines.slice(0, linesPerPage);
        pdf.text(currentPageLines, margin, yPosition);
        yPosition += (currentPageLines.length * 5);
        textLines = textLines.slice(linesPerPage);
      }

      // Get chart data before trying to render it
      const chartData = extractPercentages(horoscope.chatbot_response, horoscope.horoscope_type);

      // Only add chart if we have data
      if (chartData && chartData.length > 0) {
        if (yPosition > pdf.internal.pageSize.height - 150) {
          pdf.addPage();
          yPosition = margin;
        }

        const chartContainer = document.createElement('div');
        chartContainer.style.width = '500px';
        chartContainer.style.height = '300px';
        document.body.appendChild(chartContainer);

        const root = ReactDOM.createRoot(chartContainer);
        root.render(
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={chartData}
              margin={{ top: 20, right: 30, left: 20, bottom: 60 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="attribute"
                angle={-45}
                textAnchor="end"
                height={80}
                tick={{ fontSize: 10 }}
              />
              <YAxis domain={[0, 100]} />
              <Bar dataKey="value">
                {chartData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={barColors[index]} />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        );

        await new Promise(resolve => setTimeout(resolve, 500));

        try {
          const canvas = await html2canvas(chartContainer, {
            scale: 2,
            backgroundColor: '#ffffff'
          });

          const chartWidth = 150;
          const chartHeight = (300 * chartWidth) / 500;
          const chartX = (pageWidth - chartWidth) / 2;

          pdf.addImage(
            canvas.toDataURL('image/jpeg', 1.0),
            'JPEG',
            chartX,
            yPosition,
            chartWidth,
            chartHeight
          );
        } finally {
          root.unmount();
          document.body.removeChild(chartContainer);
        }
      }

      return pdf;
    } catch (error) {
      console.error('Error generating PDF:', error);
      throw error;
    }
  };

  const handleEmailPDF = async (horoscope) => {
    setCurrentHoroscope(horoscope);
    setEmailModalOpen(true);
  };

  const handleEmailSubmit = async () => {
    if (!emailInput || !currentHoroscope) return;

    try {
      setProcessingEmail(true);
      console.log('Generating PDF...');
      const pdf = await generatePDF(currentHoroscope);
      const pdfData = pdf.output('datauristring').split(',')[1];
      console.log('PDF generated successfully');

      console.log('Sending email request...');
      const response = await axios.post('/api/saved-horoscopes/send-horoscope-email', {
        pdfData,
        email: emailInput,
        horoscopeId: currentHoroscope.id,
        horoscopeType: currentHoroscope.horoscope_type
      });
      console.log('Email response:', response.data);

      showNotification({
        title: 'Success',
        message: 'Email sent successfully',
        color: 'green'
      });

      // Reset the form
      setEmailInput('');
      setEmailModalOpen(false);
    } catch (error) {
      console.error('Email error:', error.response || error);
      showNotification({
        title: 'Error',
        message: error.response?.data?.message || 'Failed to send email',
        color: 'red'
      });
    } finally {
      setProcessingEmail(false);
    }
  };

  const exportToPDF = async (horoscope) => {
    try {
      const pdf = await generatePDF(horoscope);
      // Actually save/download the PDF with a filename
      pdf.save(`horoscope-${formatDateTime(horoscope.input_date)}.pdf`);
    } catch (error) {
      console.error('PDF generation error:', error);
      showNotification({
        title: 'Error',
        message: 'Failed to generate PDF',
        color: 'red'
      });
    }
  };

  const handleShopClick = (horoscope) => {
    setCurrentShopHoroscope(horoscope);
    // Wait for state update and DOM render
    setTimeout(() => {
      spiralModelRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, 100);
  };

  const handleAIAnalysis = async (horoscope) => {
    try {
      const response = await axios.post(`/saved-horoscopes/${horoscope.id}/ai-analysis`);
      showNotification({
        title: 'AI Analysis',
        message: response.data.message,
        color: 'blue'
      });
    } catch (error) {
      showNotification({
        title: 'Error',
        message: error.response?.data?.error || 'Failed to perform AI analysis',
        color: 'red'
      });
    }
  };

  return (
    <Stack spacing="xl">
      {currentShopHoroscope && (
        <Paper p="md" withBorder ref={spiralModelRef}>
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '1rem' }}>
            <CloseButton onClick={() => setCurrentShopHoroscope(null)} />
          </div>
          <ShopM 
            horoscopeId={currentShopHoroscope.id}
            horoscopeType={currentShopHoroscope.horoscope_type}
            inputDate={currentShopHoroscope.input_date}
            birthDate={currentShopHoroscope.birth_date || (Array.isArray(currentShopHoroscope.birth_dates) ? currentShopHoroscope.birth_dates[0] : '')}
            latitude={currentShopHoroscope.latitude}
            longitude={currentShopHoroscope.longitude}
          />
        </Paper>
      )}

      {paginatedHoroscopes.map((horoscope) => {
        try {
          const chartData = extractPercentages(horoscope.chatbot_response, horoscope.horoscope_type);
          const createdAt = horoscope.created_at || horoscope.createdAt || horoscope.input_date;
          
          return (
            <Paper 
              key={`horoscope-${horoscope.id}`} 
              p="md" 
              withBorder
            >
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: 'xs' }}>
                <Title order={3} style={{ marginRight: 'auto', fontSize: 'clamp(14px, 1.5vw, 20px)' }}>
                  Chart #{horoscope.id} - Created: {formatDateTime(createdAt)}
                </Title>
                <div style={{ display: 'flex', gap: '4px' }}>
                  <Button
                    styles={{
                      root: {
                        padding: '4px 8px',
                        minWidth: '32px',
                        height: '32px',
                      }
                    }}
                    color="blue"
                    variant="filled"
                    onClick={() => handleEmailPDF(horoscope)}
                  >
                    <IconMail size={16} />
                  </Button>

                  <Button
                    styles={{
                      root: {
                        padding: '4px 8px',
                        minWidth: '32px',
                        height: '32px',
                      }
                    }}
                    color="blue"
                    variant="filled"
                    onClick={() => exportToPDF(horoscope)}
                  >
                    <IconDownload size={16} />
                  </Button>

                  <Button 
                    styles={{
                      root: {
                        padding: '4px 8px',
                        minWidth: '32px',
                        height: '32px',
                      }
                    }}
                    color="violet" 
                    variant="filled"
                    onClick={() => handleShopClick(horoscope)}
                  >
                    <IconShoppingCart size={16} />
                  </Button>

                  {horoscope.showAIButton && (
                    <Button 
                      styles={{
                        root: {
                          padding: '4px 8px',
                          minWidth: '32px',
                          height: '32px',
                        }
                      }}
                      color="cyan" 
                      variant="filled"
                      onClick={() => handleAIAnalysis(horoscope)}
                    >
                      <IconBrain size={16} />
                    </Button>
                  )}

                  <Button 
                    styles={{
                      root: {
                        padding: '4px 8px',
                        minWidth: '32px',
                        height: '32px',
                      }
                    }}
                    color="red" 
                    variant="filled"
                    onClick={() => handleDeleteHoroscope(horoscope.id)}
                  >
                    <IconTrash size={16} />
                  </Button>
                </div>
              </div>

              <div id={`horoscope-content-${horoscope.id}`} style={{ wordWrap: 'break-word', whiteSpace: 'pre-wrap' }}>
                <Text style={{ maxWidth: '100%', wordWrap: 'break-word' }}>
                  <strong>Type:</strong> {formatHoroscopeType(horoscope.horoscope_type)}
                </Text>
                <Text><strong>Input Date:</strong> {formatDateTime(horoscope.input_date)}</Text>
                <Text>
                  <strong>Birth Date:</strong> {
                    (() => {
                      try {
                        // Handle stringified JSON array
                        if (typeof horoscope.birth_dates === 'string' && horoscope.birth_dates.startsWith('[')) {
                          const parsedDates = JSON.parse(horoscope.birth_dates);
                          return formatDateTime(parsedDates[0].replace(/\\/g, '')); // Remove escape characters
                        }
                        // Existing checks
                        else if (Array.isArray(horoscope.birth_dates) && horoscope.birth_dates.length > 0) {
                          return formatDateTime(horoscope.birth_dates[0]);
                        } 
                        else if (horoscope.birth_date) {
                          return formatDateTime(horoscope.birth_date);
                        } 
                        else {
                          console.log('Birth date format:', {
                            type: typeof horoscope.birth_dates,
                            value: horoscope.birth_dates
                          });
                          return 'Not specified';
                        }
                      } catch (error) {
                        console.error('Error parsing birth date:', error);
                        return 'Error displaying date';
                      }
                    })()
                  }
                </Text>
                <Text><strong>Latitude:</strong> {horoscope.latitude}</Text>
                <Text><strong>Longitude:</strong> {horoscope.longitude}</Text>

                <Title order={4} mt="md">Horoscope Interpretation</Title>
                {formatChatbotResponse(horoscope.chatbot_response)}

                {chartData && chartData.length > 0 && (
                  <Box 
                    mt="lg" 
                    h={450} 
                    style={{ 
                      width: '100%',
                      margin: '0 auto',
                      maxWidth: '600px',
                      padding: '0 10px',
                    }}
                  >
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart
                        data={chartData}
                        margin={{ 
                          top: 20, 
                          right: 20,
                          left: 20, 
                          bottom: 100 
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis 
                          dataKey="attribute" 
                          angle={-45} 
                          textAnchor="end" 
                          height={100}
                          interval={0}
                          tick={{ 
                            fontSize: 10,
                            dy: 10 
                          }}
                        />
                        <YAxis domain={[0, 100]} />
                        <Tooltip content={<CustomTooltip />} />
                        <Bar dataKey="value">
                          {chartData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={barColors[index]} />
                          ))}
                        </Bar>
                      </BarChart>
                    </ResponsiveContainer>
                  </Box>
                )}
              </div>
            </Paper>
          );
        } catch (error) {
          console.error('Error rendering horoscope:', error);
          return (
            <Paper key={`error-${horoscope.id}`} p="md" withBorder>
              <Text color="red">Error displaying horoscope #{horoscope.id}</Text>
            </Paper>
          );
        }
      })}

      {savedHoroscopes.length > itemsPerPage && (
        <Group position="center">
          <Button 
            onClick={() => setPage(p => Math.max(1, p - 1))}
            disabled={page === 1}
          >
            Previous
          </Button>
          <Text>Page {page} of {Math.ceil(savedHoroscopes.length / itemsPerPage)}</Text>
          <Button 
            onClick={() => setPage(p => p + 1)}
            disabled={page >= Math.ceil(savedHoroscopes.length / itemsPerPage)}
          >
            Next
          </Button>
        </Group>
      )}

      <Modal
        opened={emailModalOpen}
        onClose={() => {
          setEmailModalOpen(false);
          setEmailInput('');
        }}
        title="Send Horoscope PDF"
        size="sm"
        centered
      >
        <form onSubmit={(e) => {
          e.preventDefault();
          handleEmailSubmit();
        }}>
          <TextInput
            required
            label="Email Address"
            placeholder="Enter recipient's email"
            value={emailInput}
            onChange={(event) => setEmailInput(event.currentTarget.value)}
            mb="md"
            type="email"
          />
          <Group position="right">
            <Button
              variant="subtle"
              onClick={() => {
                setEmailModalOpen(false);
                setEmailInput('');
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              loading={processingEmail}
              disabled={!emailInput}
            >
              Send
            </Button>
          </Group>
        </form>
      </Modal>
    </Stack>
  );
};

export default SavedHoroscopeDisplay;